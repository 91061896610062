import { ChartDashVenda } from '../../components/Charts/ChartDashVenda';
import { ChartDashPagamento } from '../../components/Charts/ChartDashPagamento';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Container } from '../../styles/GlobalStyles';
import { Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import { fetchGestaoTaxa } from '../../store/states/gestao-taxa/actions';
import { fetchAdquirente } from '../../store/states/adquirente/actions';
import { fetchBandeira } from '../../store/states/bandeira/actions';
import { fetchModalidade } from '../../store/states/modalidade/actions';
import { fetchBanco } from '../../store/states/banco/actions';
import AnimatedBanner from '../../components/AnimatedBanner';
import PieChartDash from '../../components/Charts/PieChartDash';
import Lottie from 'react-lottie';
import animationData from '../../assets/dashboard/ninja.json';

function DashboardScreen() {
  const dispatch = useDispatch();
  const vendaChartData = useSelector((state: RootState) => state.venda.vendaChartData);
  const vendaTotals = useSelector((state: RootState) => state.venda.vendaTotals);
  const pagamentoChartData = useSelector((state: RootState) => state.pagamento.pagamentoChartData);
  const pagamentoTotals = useSelector((state: RootState) => state.pagamento.pagamentoTotals);
  const gestaoTaxa = useSelector((state: RootState) => state.gestaoTaxa.data);
  const banco = useSelector((state: RootState) => state.banco.data);
  const adquirente = useSelector((state: RootState) => state.adquirente.data);
  const bandeira = useSelector((state: RootState) => state.bandeira.data);
  const modalidade = useSelector((state: RootState) => state.modalidade.data);
  const [visibleAnimation, setVisibleAnimation] = useState(false);
  const [animationPlay, setAnimationPlay] = useState(false);
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.keyCode === 113) {
      setVisibleAnimation(true);
      setAnimationPlay(true);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (!visibleAnimation) return;
    const duration = 5000;
    const timer = setTimeout(() => {
      setVisibleAnimation(false);
      setAnimationPlay(false);
    }, duration, animationPlay);

    return () => clearTimeout(timer);
  }, [visibleAnimation]);

  useEffect(() => {
    if (!gestaoTaxa) {
      dispatch(fetchGestaoTaxa());
    }
    if (!banco) {
      dispatch(fetchBanco());
    }
    if (!adquirente) {
      dispatch(fetchAdquirente());
    }
    if (!bandeira) {
      dispatch(fetchBandeira());
    }
    if (!modalidade) {
      dispatch(fetchModalidade());
    }
  }, []);

  return (
    <AnimatedBanner>
      <Container>
        {visibleAnimation && <>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
            <Lottie options={defaultOptions} height={100} width={100} />
          </div>
        </>}
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <ChartDashVenda vendaTotals={vendaTotals} vendaChartData={vendaChartData} />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <ChartDashPagamento pagamentoTotals={pagamentoTotals} pagamentoChartData={pagamentoChartData} />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <PieChartDash titleBoard="Estatísticas Vendas" transactions={vendaChartData} />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <PieChartDash titleBoard="Estatísticas Pagamentos" transactions={pagamentoChartData} />
          </Col>
        </Row>
      </Container>
    </AnimatedBanner>
  );
}

export default DashboardScreen;
