import { useEffect, useState } from 'react';
import { Modal, Upload, UploadFile, message } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined, RetweetOutlined, SendOutlined, UploadOutlined } from '@ant-design/icons';
import animationData from '../../assets/banking/wallet.json';
import Lottie from 'react-lottie';
import { ButtonAlterarOfxStyled, ButtonEnviarOfxStyled, UploadContainer, UploadStatusContainer } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUploadOfx, resetUploadStatus } from '../../store/states/bankingOfx/actions';
import { RootState } from '../../store/types';

const { Dragger } = Upload;

interface UploadStatus {
  name: string;
  status: boolean;
  data: string;
}

const UploadOFX = () => {
  const [visible, setVisible] = useState(false);
  const [resultVisible, setResultVisible] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const dispatch = useDispatch();
  const uploadStatus = useSelector((state: RootState) => state.bankingOfx.status_upload) as UploadStatus[];
  const bankingOfxUploadLoading = useSelector((state: RootState) => state.bankingOfx.loading_upload);

  useEffect(() => {
    if (uploadStatus.length > 0) {
      setResultVisible(true);
    }
  }, [uploadStatus]);

  const handleUpload = () => {
    try {
      const actualFile = fileList[0]?.originFileObj;
      if (actualFile) {
        dispatch(fetchUploadOfx(actualFile));
      } else {
        throw new Error('Arquivo real não encontrado');
      }
      setVisible(false);
      setFileList([]);
    } catch (error) {
      console.error('Erro ao enviar arquivos:', error);
      message.error('Erro ao enviar arquivos. Por favor, tente novamente.');
    }
  };

  const handleCloseResultModal = () => {
    setFileList([]);
    setResultVisible(false);
    dispatch(resetUploadStatus());
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const fileProps = {
    name: 'file',
    multiple: false,
    fileList,
    onChange(info: any) {
      const { status, name } = info.file;
      const newFileList = info.fileList.map((file: any) => ({
        ...file,
        status: file.uid === info.file.uid ? status : file.status,
      }));
      setFileList(newFileList);
      if (status === 'done') {
        message.success(`${name} foi carregado com sucesso.`);
      } else if (status === 'error') {
        message.error(`${name} falhou ao carregar.`);
      }
    },
    onDrop(e: any) { },
    beforeUpload(file: any) {
      const fileExtension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
      const isOFX = fileExtension.toLowerCase() === 'ofx';
      if (!isOFX) {
        message.warning('Você só pode enviar arquivos .ofx!');
        return Upload.LIST_IGNORE;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.warning('O arquivo deve ser menor que 2MB!');
        return Upload.LIST_IGNORE;
      }
      setFileList([file]);
      return false;
    },
  };

  return (
    <>
      <ButtonEnviarOfxStyled icon={<UploadOutlined />} type="primary" onClick={() => setVisible(true)}>
        Upload de OFX
      </ButtonEnviarOfxStyled>
      <Modal
        visible={visible}
        title="Upload de Arquivos"
        onCancel={() => {
          setVisible(false);
          setFileList([]);
        }}
        footer={null}
        centered
        style={{ top: -180 }}
      >
        {fileList.length === 0 ? (
          <Dragger {...fileProps}>
            <p className="ant-upload-drag-icon">
              <Lottie options={defaultOptions} height={100} width={100} />
            </p>
            <p className="ant-upload-text">Clique ou arraste arquivos para esta área para fazer o upload</p>
            <p className="ant-upload-hint">Suporte para múltiplos arquivos .ofx por vez. Cada arquivo deve ser menor que 2MB.</p>
          </Dragger>
        ) : (
          <UploadContainer>
            {fileList[0].name}
            <Upload {...fileProps} showUploadList={false}>
              <ButtonAlterarOfxStyled icon={<RetweetOutlined />} />
            </Upload>
          </UploadContainer>
        )}
        {fileList.length > 0 && fileList.some((file: any) => file.status !== 'done') && (
          <ButtonEnviarOfxStyled icon={<SendOutlined />} onClick={handleUpload} disabled={fileList.every((file: any) => file.status === 'done')}>
            ENVIAR AGORA
          </ButtonEnviarOfxStyled>
        )}
      </Modal>
      {bankingOfxUploadLoading && (
        <Modal visible={bankingOfxUploadLoading} title={null} onCancel={handleCloseResultModal} footer={null} centered style={{ top: -180 }}>
          <UploadStatusContainer>
            <h3>
              {' '}
              <LoadingOutlined style={{ marginRight: '15px', color: '#536390' }} />
              Aguarde, o arquivo está sendo processado!
            </h3>
          </UploadStatusContainer>
        </Modal>
      )}
      <Modal visible={resultVisible} title={'Resultado do Upload'} onCancel={handleCloseResultModal} footer={null} centered style={{ top: -180 }}>
        <UploadStatusContainer>
          {uploadStatus.length > 0 &&
            uploadStatus.map((status, index) => (
              <div key={index}>
                <h3>
                  {status.status ? (
                    <>
                      <CheckCircleOutlined style={{ marginRight: '10px', color: 'green' }} />
                      {status.name}
                    </>
                  ) : (
                    <>
                      <CloseCircleOutlined style={{ marginRight: '10px', color: 'red' }} />
                      {status.name}
                    </>
                  )}
                </h3>
                <p>{status.data}</p>
                {status.status && <p>Em alguns instantes as conciliações estarão disponíveis para consulta.</p>}
              </div>
            ))}
        </UploadStatusContainer>
      </Modal>
    </>
  );
};

export default UploadOFX;
