import { takeLatest, call, put, all } from 'redux-saga/effects';
import { processamentoApi } from '../../target-api/api';
import { SEND_PROCESS_QUEUE } from './types';
import { sendProcessQueueFailure, sendProcessQueueSuccess } from './actions';
import { message } from 'antd';

export function* sendProcessQueueSaga(action: any): Generator<any, void, any> {
  try {
    let { client_id, fila } = action.payload;
    const [response] = yield all([call(processamentoApi, client_id, fila)]);
    message.success(response.message);
    yield put(sendProcessQueueSuccess());
  } catch (error: any) {
    yield put(sendProcessQueueFailure(error.message));
  }
}

export function* watchSendProcessQueueSaga() {
  yield takeLatest(SEND_PROCESS_QUEUE, sendProcessQueueSaga);
}
