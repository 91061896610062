import { takeLatest, call, put } from 'redux-saga/effects';
import { clientesApi, updateClienteApi } from '../../target-api/api';
import { message } from 'antd';
import { fetchClienteSuccess, fetchClienteFailure, fetchCliente } from './actions';
import { FETCH_CLIENTE, UPDATE_CLIENTE_STATUS } from './types';

export function* fetchClienteSaga() {
  try {
    const data: any[] = yield call(clientesApi);
    if (data !== undefined) {
      yield put(fetchClienteSuccess(data));
    } else {
      message.warning('Falha ao retornar lista de clientes!');
    }
  } catch (error: any) {
    message.error('Falha ao buscar clientes.');
    yield put(fetchClienteFailure(error.toString()));
  }
}

export function* updateClienteStatusSaga(action: any) {
  try {
    const { clienteId, status } = action.payload;
    yield call(updateClienteApi, clienteId, status);
    message.success('Status do cliente atualizado com sucesso!');
    yield put(fetchCliente());
  } catch (error: any) {
    message.error('Falha ao atualizar status do cliente.');
  }
}

export function* watchClienteSaga() {
  yield takeLatest(FETCH_CLIENTE, fetchClienteSaga);
  yield takeLatest(UPDATE_CLIENTE_STATUS, updateClienteStatusSaga);
}
