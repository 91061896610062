import { Image, Select, Tooltip } from 'antd';
import { adquirenteNomeParaImagem, bandeiraNomeParaImagens, modalidades } from '../../../styles/globalInterfaces';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

const { Option } = Select;

export const columnsGestaoTaxa = (): ColumnsType<any> => {
  const columns: ColumnsType<any> = [
    {
      title: 'Data de Criação',
      dataIndex: 'gestaotaxacreatedat',
      key: 'gestaotaxacreatedat',
      sorter: (a, b) => moment(a.gestaotaxacreatedat).unix() - moment(b.gestaotaxacreatedat).unix(),
      render: text => <div>{moment(text).format('DD/MM/YYYY HH:mm:ss')}</div>,
    },
    {
      title: 'Situação',
      dataIndex: 'status_vigencia',
      key: 'status_vigencia',
      render: (text: string, record: any) => {
        return (
          <div>{text}</div>
        );
      },
    },
    {
      title: 'Vigência',
      dataIndex: 'dias_restantes',
      key: 'dias_restantes',
      render: (text: string, record: any) => {
        return (
          <div>{text} dia(s)</div>
        );
      },
    },
    {
      title: 'Empresa',
      dataIndex: 'empresanome',
      key: 'empresanome',
    },
    {
      title: 'Estabelecimento',
      dataIndex: 'estabelecimentonumero',
      key: 'estabelecimentonumero',
      sorter: (a: any, b: any) => {
        return a.estabelecimentonumero - b.estabelecimentonumero;
      },
    },
    {
      title: 'Usuário',
      dataIndex: 'usuarionome',
      key: 'usuarionome',
    },
    {
      title: 'Adquirente',
      dataIndex: 'adquirentenome',
      key: 'adquirentenome',
      render: (text: string, record: any) => {
        const adquirenteName = record.adquirentenome ?? '';
        return (
          <div>
            <Image preview={false} width={25} src={adquirenteNomeParaImagem[adquirenteName.toLowerCase().replace(/\s/g, '')]} alt={adquirenteName} />
          </div>
        );
      },
      sorter: (a: any, b: any) => {
        if (a.adquirentenome && b.adquirentenome) {
          return a.adquirentenome.localeCompare(b.adquirentenome);
        }
        return 0;
      },
    },
    {
      title: 'Bandeira',
      dataIndex: 'bandeiranome',
      key: 'bandeiranome',
      render: (text: string, record: any) => {
        const bandeiraName = (record.bandeiranome ?? '').toLowerCase() === 'não informado' ? 'nao_informado' : (record.bandeiranome ?? '').toLowerCase();
        return (
          <Tooltip title={record.bandeiranome} placement="left">
            <div>
              <Image width={25} src={bandeiraNomeParaImagens[bandeiraName]} alt={record.bandeiranome} preview={false} />
            </div>
          </Tooltip>
        );
      },
      sorter: (a: any, b: any) => {
        if (a.bandeiranome && b.bandeiranome) {
          return a.bandeiranome.localeCompare(b.bandeiranome);
        }
        return 0;
      },
    },
    {
      title: 'Taxa',
      dataIndex: 'gestaotaxavalortaxa',
      key: 'gestaotaxavalortaxa',
      render: (text: number) => <span>{text !== undefined && text.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %</span>,
      sorter: (a: any, b: any) => {
        return a.gestaotaxavalortaxa - b.gestaotaxavalortaxa;
      },
    },
    {
      title: 'Modalidade',
      dataIndex: 'modalidadenome',
      key: 'modalidadenome',
      render: (text: string, record: any) => <div>{record.modalidadenome}</div>,
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select allowClear placeholder="Selecione a modalidade" style={{ width: 200, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {modalidades.map((modalidade: any) => (
              <Option key={modalidade.id} value={modalidade.name}>
                {modalidade.nome}
              </Option>
            ))}
          </Select>
          <button type="button" onClick={() => confirm?.()} style={{ width: 90, marginRight: 8 }}>
            Buscar
          </button>
          <button type="button" onClick={() => clearFilters?.()} style={{ width: 90 }}>
            Limpar
          </button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value === record.modalidadenome;
      },
      sorter: (a: any, b: any) => {
        if (a.modalidadenome && b.modalidadenome) {
          return a.modalidadenome.localeCompare(b.modalidadenome);
        }
        return 0;
      },
    },
    {
      title: 'Qtde. Parcela(as)',
      dataIndex: 'gestaotaxaparcela',
      key: 'gestaotaxaparcela',
    },
  ];
  return columns;
};
