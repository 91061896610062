import {
  SEND_PROCESS_QUEUE,
  SEND_PROCESS_QUEUE_SUCCESS,
  SEND_PROCESS_QUEUE_FAILURE,
} from './types';

export const sendProcessQueue = (payload: { client_id: number; fila: string; }) => ({
  type: SEND_PROCESS_QUEUE,
  payload
});

export const sendProcessQueueSuccess = () => ({
  type: SEND_PROCESS_QUEUE_SUCCESS
});

export const sendProcessQueueFailure = (payload: any) => ({
  type: SEND_PROCESS_QUEUE_FAILURE,
  payload
});
