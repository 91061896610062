import { CLEAR_EMPRESA_DATA, FETCH_EMPRESA, FETCH_EMPRESA_FAILURE, FETCH_EMPRESA_SUCCESS, UPDATE_EMPRESA_ID, UPDATE_EMPRESA_NOME, UPDATE_EMPRESA_NOME_FAILURE, UPDATE_EMPRESA_NOME_SUCCESS, UPDATE_EMPRESA_STATUS, UPDATE_EMPRESA_STATUS_FAILURE, UPDATE_EMPRESA_STATUS_SUCCESS } from './types';

export const clearCompanyData = () => ({
  type: CLEAR_EMPRESA_DATA,
});

export const fetchEmpresa = () => ({
  type: FETCH_EMPRESA,
});

export const fetchEmpresaSuccess = (data: any) => ({
  type: FETCH_EMPRESA_SUCCESS,
  payload: data,
});

export const fetchEmpresaFailure = (error: any) => ({
  type: FETCH_EMPRESA_FAILURE,
  payload: error,
});

export const updateCompanyID = (companyIDs: number[]) => ({
  type: UPDATE_EMPRESA_ID,
  payload: companyIDs,
});

export const updateEmpresaStatus = (empresaId: string, status: boolean) => ({
  type: UPDATE_EMPRESA_STATUS,
  payload: { empresaId, status }
});

export const updateEmpresaStatusSuccess = () => ({
  type: UPDATE_EMPRESA_STATUS_SUCCESS
});

export const updateEmpresaStatusFailure = (error: any) => ({
  type: UPDATE_EMPRESA_STATUS_FAILURE,
  payload: error,
});

export const updateEmpresaNome = (empresaId: string, nome: string) => ({
  type: UPDATE_EMPRESA_NOME,
  payload: { empresaId, nome }
});

export const updateEmpresaNomeSuccess = () => ({
  type: UPDATE_EMPRESA_NOME_SUCCESS
});

export const updateEmpresaNomeFailure = (error: any) => ({
  type: UPDATE_EMPRESA_NOME_FAILURE,
  payload: error,
});
