import moment from 'moment';
import api from '../../../services/axios';
import { message } from 'antd';
import saveAs from 'file-saver';

export async function agendaRecebimentosTotalsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
    const baseURL = '/agenda-recebimentos/recebimentosTotals';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function agendaRecebimentosApi(company_id: number[] | number, start_date: string, end_date: string, page: number = 1, pageSize: number = 10, filters: Record<string, any> = {}, sortField: any, sortOrder: any): Promise<any> {
    const baseURL = '/agenda-recebimentos/all';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
        page: page.toString(),
        pageSize: pageSize.toString(),
        sortField: sortField.toString(),
        sortOrder: sortOrder.toString(),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value !== undefined && value !== null && value !== '') {
            params.append(key, value.toString());
        }
    });
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function agendaRecebimentosChartDataApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
    const baseURL = '/agenda-recebimentos/recebimentosChartData';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function agendaRecebimentosXlsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
    const baseURL = '/agenda-recebimentos/export/xls';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    try {
        const response = await api.get(url, {
            responseType: 'blob',
            headers: {
                Accept: 'application/vnd.ms-excel',
            },
        });
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'relatorio_recebiveis.xlsx');
    } catch (error) {
        message.error(`Erro ao baixar o arquivo XLS: ${error}`);
    }
}

export async function agendaRecebimentosPdfApi(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
    const baseURL = '/agenda-recebimentos/export/pdf';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    try {
        const response = await api.get(url, {
            responseType: 'blob',
        });
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, 'relatorio_recebiveis.pdf');
    } catch (error) {
        message.error(`Erro ao baixar o arquivo PDF: ${error}`);
    }
}