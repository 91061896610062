import { Col, Row, Skeleton } from 'antd';
import { DeliveredProcedureOutlined, PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { Container } from '../../styles/GlobalStyles';
import TableComponent from '../../components/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { useEffect, useState } from 'react';
import ModalRateRegister from '../../components/Modals/ModalRateRegister';
import { updateGestaoTaxaStatus, fetchGestaoTaxa, updateGestaoTaxa } from '../../store/states/gestao-taxa/actions';
import { columnsGestaoTaxa } from './interfaces/columnsGestaoTaxa';
import { ModalGestaoTaxasActions } from '../../components/Modals/ModalGestaoTaxasActions';
import { StatCard } from '../../components/Charts/StatCard';
import AnimatedBanner from '../../components/AnimatedBanner';
import ButtonRefreshList from '../../components/Common/ButtonRefreshList';
import ModalRateImport from '../../components/Modals/ModalRateImport';

function GestaoTaxaScreen() {
  const dispatch = useDispatch();
  const gestaoTaxa = useSelector((state: RootState) => state.gestaoTaxa.data);
  const loadingGestaoTaxas = useSelector((state: RootState) => state.gestaoTaxa.loading);
  const [taxas, setTaxas] = useState([]);
  const [totalTaxas, setTotalTaxas] = useState(0);
  const [totalAtivas, setTotalAtivas] = useState(0);
  const [totalInativas, setTotalInativas] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalImportOpen, setIsModalImportOpen] = useState(false);

  useEffect(() => {
    if (gestaoTaxa && gestaoTaxa.gestaotaxas) {
      setTaxas(gestaoTaxa.gestaotaxas);
      setTotalTaxas(gestaoTaxa.total_gestaotaxas);
      setTotalAtivas(gestaoTaxa.total_ativas);
      setTotalInativas(gestaoTaxa.total_inativas);
    }
  }, [gestaoTaxa]);

  const handleCreateClick = () => {
    setIsModalOpen(true);
  };

  const handleImportClick = () => {
    setIsModalImportOpen(true);
  };

  const handleUpdateClick = () => {
    dispatch(fetchGestaoTaxa());
  };

  const toggleStatus = (gestaoTaxaId: any, status: any) => {
    dispatch(updateGestaoTaxaStatus(gestaoTaxaId, status));
  };

  const handleEdit = (rateId: number, updatedData: any) => {
    dispatch(updateGestaoTaxa(rateId, updatedData));
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeImportModal = () => {
    setIsModalImportOpen(false);
  };

  return (
    <AnimatedBanner>
      <Container>
        <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
          <Col flex="5">
            <StatCard loadingData={loadingGestaoTaxas} title="Total Taxas" value={totalTaxas} />
          </Col>
          <Col flex="5">
            <StatCard loadingData={loadingGestaoTaxas} title="Ativas" value={totalAtivas} />
          </Col>
          <Col flex="5">
            <StatCard loadingData={loadingGestaoTaxas} title="Inativas" value={totalInativas} />
          </Col>
        </Row>
        {loadingGestaoTaxas ?
          <Skeleton active />
          :
          <>
            <ButtonRefreshList loading={loadingGestaoTaxas} icon={<DeliveredProcedureOutlined />} onClick={handleImportClick} text='Importar Taxas' />
            <ButtonRefreshList loading={loadingGestaoTaxas} icon={<PlusCircleOutlined />} onClick={handleCreateClick} text='Nova Taxa' />
            <ButtonRefreshList loading={loadingGestaoTaxas} icon={<ReloadOutlined />} onClick={handleUpdateClick} text='Recarregar' />
            <TableComponent
              loadingData={loadingGestaoTaxas}
              data={taxas}
              columns={columnsGestaoTaxa()}
              rowKeyId="gestaotaxaid"
              rowRender={(record: any) => <ModalGestaoTaxasActions record={record} handleChangeStatus={toggleStatus} handleEdit={handleEdit} />}
              perPage={10}
              expand={true}
            />
          </>}
        <ModalRateRegister title={'Nova Taxa de Contrato'} isOpen={isModalOpen} onClose={closeModal} />
        <ModalRateImport title={'Importar Taxas'} isOpen={isModalImportOpen} onClose={closeImportModal} />
      </Container>
    </AnimatedBanner>
  );
}

export default GestaoTaxaScreen;
