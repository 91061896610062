import { useState } from 'react';
import { Card, Col, Row, Select, Skeleton, message } from 'antd';
import { Container } from '../../styles/GlobalStyles';
import { StatCard } from '../../components/Charts/StatCard';
import AnimatedBanner from '../../components/AnimatedBanner';
import ButtonRefreshList from '../../components/Common/ButtonRefreshList';
import { SendOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { sendProcessQueue } from '../../store/states/processamento/actions';

const { Option } = Select;

function ProcessamentoScreen() {
    const dispatch = useDispatch();
    const cliente = useSelector((state: RootState) => state.cliente.data);
    const loadingProcess = useSelector((state: RootState) => state.processamento.loading);
    const [clienteId, setClienteId] = useState<string>('');
    const [fila, setFila] = useState<string>('');

    const handleProcess = () => {
        if (!clienteId || !fila) {
            message.warning('Por favor, selecione o cliente e a fila.');
            return;
        }
        dispatch(sendProcessQueue({ client_id: parseInt(clienteId), fila }));
        setClienteId('');
        setFila('');
    };

    return (
        <AnimatedBanner>
            <Container>
                <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
                    <Col flex="5">
                        <StatCard loadingData={false} title="Processamento de Arquivos" value="Selecione abaixo o cliente e o serviço desejado para continuar" />
                    </Col>
                </Row>
                {loadingProcess ? (
                    <>
                        <Skeleton active />
                    </>
                ) : (
                    <>
                        <Row gutter={16} style={{ marginBottom: 10 }}>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Selecione o cliente"
                                    style={{ width: '100%' }}
                                    onChange={(value: string) => setClienteId(value)}
                                    filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {cliente.clientes.sort((a: any, b: any) => a.clientenome.localeCompare(b.clientenome)).map((cliente: any) => (
                                        <Select.Option key={cliente.clienteid} value={cliente.clienteid}>
                                            {cliente.clientenome}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={12}>
                                <Select
                                    placeholder="Selecione a Fila"
                                    onChange={(value: string) => setFila(value)}
                                    style={{ width: '100%' }}>
                                    <Option value="clean-duplicity-in">MS - clean-duplicity (Limpar duplicidades)</Option>
                                    <Option value="transaction-reconciliation-in">MS - transaction-reconciliation (Conciliação financeira)</Option>
                                </Select>
                            </Col>
                        </Row>
                    </>
                )}
                <Row>
                    <Col span={24}>
                        <ButtonRefreshList loading={loadingProcess} icon={<SendOutlined />} onClick={handleProcess} text='Iniciar Processamento' />
                    </Col>
                </Row>
            </Container>
        </AnimatedBanner>
    );
}

export default ProcessamentoScreen;
