import { Card, Col, Row } from 'antd';
import {
    AlertOutlined,
    BankOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    EditOutlined,
    FieldNumberOutlined,
    PaperClipOutlined,
    PercentageOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import ButtonRefreshList from '../../../components/Common/ButtonRefreshList';

export const RenderEmpresa = (
    toggleStatus: (empresaId: string, status: boolean) => void,
    showModal: (empresaId: string) => void) => (record: any) => {
        return (
            <Card bordered={false} style={{ marginTop: 16, marginBottom: 16, cursor: 'pointer' }}>
                <Row gutter={16}>
                    <Col span={6}>
                        <div>
                            <ClockCircleOutlined style={{ marginRight: 10 }} />
                            <span>Data de criação: </span>
                            <strong>{moment(record.empresacreatedat).format('DD/MM/YYYY')}</strong>
                        </div>
                        <div>
                            <BankOutlined style={{ marginRight: 10 }} />
                            <span>Nome: </span>
                            <strong>{record.empresanome}</strong>
                        </div>
                        <div>
                            <FieldNumberOutlined style={{ marginRight: 10 }} />
                            <span>Documento: </span>
                            <strong>{record.empresadocumento}</strong>
                        </div>
                        <div>
                            <PaperClipOutlined style={{ marginRight: 10 }} />
                            <span>E-mail: </span>
                            <strong>{record.empresaemail}</strong>
                        </div>
                        <div>
                            <PercentageOutlined style={{ marginRight: 10 }} />
                            <span>Telefone: </span>
                            <strong>{record.empresatelefone}</strong>
                        </div>
                        <div style={{ marginTop: 15, marginBottom: 15 }}>
                            <AlertOutlined style={{ marginRight: 10 }} />
                            <strong>Ações</strong>
                        </div>
                        <ButtonRefreshList loading={false} icon={record.empresastatus ? <CloseOutlined /> : <CheckOutlined />} onClick={() => toggleStatus(record.empresaid, record.empresastatus)} text={record.empresastatus ? 'Desativar' : 'Ativar'} />
                        <ButtonRefreshList loading={false} icon={<EditOutlined />} onClick={() => showModal(record.empresaid)} text='Renomear Empresa' />
                    </Col>
                </Row>
            </Card >
        );
    };
