import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Container } from '../../styles/GlobalStyles';
import { useEffect, useState } from 'react';
import AnimatedBanner from '../../components/AnimatedBanner';
import TableComponent from '../../components/TableComponent';
import { columnsCliente } from './interfaces/columnsCliente';
import { Col, Row } from 'antd';
import { StatCard } from '../../components/Charts/StatCard';
import ButtonRefreshList from '../../components/Common/ButtonRefreshList';
import { ReloadOutlined } from '@ant-design/icons';
import { fetchCliente } from '../../store/states/cliente/actions';
import { RenderCliente } from './interfaces/renderCliente';

function ClienteScreen() {
    const dispatch = useDispatch();
    const cliente = useSelector((state: RootState) => state.cliente.data);
    const [clientes, setClientes] = useState([]);
    const [totalClientes, setTotalClientes] = useState(0);
    const [totalAtivos, setTotalAtivos] = useState(0);
    const [totalInativos, setTotalInativos] = useState(0);

    useEffect(() => {
        if (cliente && cliente.clientes) {
            setClientes(cliente.clientes);
            setTotalClientes(cliente.total_clientes);
            setTotalAtivos(cliente.total_ativos);
            setTotalInativos(cliente.total_inativos);
        }
    }, [dispatch, cliente]);

    const handleRefresh = () => {
        dispatch(fetchCliente());
    };

    return (
        <AnimatedBanner>
            <Container>
                <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
                    <Col flex="5">
                        <StatCard loadingData={false} title="Total Clientes" value={totalClientes} />
                    </Col>
                    <Col flex="5">
                        <StatCard loadingData={false} title="Ativos" value={totalAtivos} />
                    </Col>
                    <Col flex="5">
                        <StatCard loadingData={false} title="Inativos" value={totalInativos} />
                    </Col>
                </Row>
                <ButtonRefreshList loading={false} icon={<ReloadOutlined />} onClick={handleRefresh} text='Recarregar' />
                <TableComponent
                    loadingData={false}
                    data={clientes}
                    columns={columnsCliente()}
                    rowKeyId="clienteid"
                    rowRender={RenderCliente}
                    perPage={10}
                    expand={true}
                />
            </Container>
        </AnimatedBanner>
    );
}

export default ClienteScreen;
