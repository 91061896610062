import { FETCH_IMPORTACAO_ARQUIVO, FETCH_IMPORTACAO_ARQUIVO_SUCCESS, FETCH_IMPORTACAO_ARQUIVO_FAILURE } from './types';

export const fetchImportacaoArquivo = (page = 1, pageSize = 5) => ({
  type: FETCH_IMPORTACAO_ARQUIVO,
  payload: { page, pageSize },
});

export const fetchImportacaoArquivoSuccess = (data: any) => ({
  type: FETCH_IMPORTACAO_ARQUIVO_SUCCESS,
  payload: data,
});

export const fetchImportacaoArquivoFailure = (error: any) => ({
  type: FETCH_IMPORTACAO_ARQUIVO_FAILURE,
  payload: error,
});
