export interface LoginResponse {
  access_token: string;
}

export { loginApi } from './endpoints/auth';
export {
  userProfileApi,
  getUserRuleApi,
  recoverPasswordApi,
  resetPasswordApi,
  updateUsuarioStatusApi,
  createUsuarioApi,
  uploadPhotoApi,
  usuarioApi,
  usuarioEventoApi,
  usuarioSyncEmpresaApi
} from './endpoints/usuario';
export {
  agendaRecebimentosApi,
  agendaRecebimentosChartDataApi,
  agendaRecebimentosPdfApi,
  agendaRecebimentosTotalsApi,
  agendaRecebimentosXlsApi,
} from './endpoints/agendaRecebimento';
export {
  adquirenteApi,
  bancoApi,
  bandeiraApi,
  modalidadeApi,
  proposalApi
} from './endpoints/auxiliares';
export {
  bankingOfxPdfApi,
  bankingOfxXlsApi,
  bankingOfxApi,
  bankingOfxTransactionsApi,
  uploadOfxAPi,

} from './endpoints/bancaria';
export { clientesApi, updateClienteApi } from './endpoints/cliente';
export { empresaApi, updateNomeEmpresaApi, updateStatusEmpresaApi } from './endpoints/empresa';
export { estabelecimentoApi, updateEstabelecimentoApi } from './endpoints/estabelecimento';
export { gestaoTaxaApi, changeRateApi, createRateApi, updateRateApi, importRateApi } from './endpoints/gestaoTaxa';
export { notificationApi, updateNotificationApi } from './endpoints/notificacao';
export {
  pagamentoApi,
  pagamentoPdfApi,
  pagamentoAnalyticsApi,
  pagamentoAuditAnalyticsApi,
  pagamentoAuditadoApiPdf,
  pagamentoAuditadoApiXls,
  pagamentoChartDataApi,
  pagamentoConsolidadoApi,
  pagamentoConsolidadoFiltroApi,
  pagamentoConsolidadoPdfApi,
  pagamentoConsolidadoXlsApi,
  pagamentoTotalsApi,
  pagamentoXlsApi
} from './endpoints/pagamento';
export {
  vendaApi,
  vendaPdfApi,
  vendaXlsApi,
  vendaAnalyticsApi,
  vendaAuditAnalyticsApi,
  vendaAuditadaApiPdf,
  vendaAuditadaApiXls,
  vendaChartDataApi,
  vendaTotalsApi
} from './endpoints/venda';
export { importacaoArquivoApi } from './endpoints/importacaoArquivo';
export { logsApi } from './endpoints/log';
export { processamentoApi } from './endpoints/processamento';
