import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Container } from '../../styles/GlobalStyles';
import { useEffect, useState } from 'react';
import AnimatedBanner from '../../components/AnimatedBanner';
import TablePagination from '../../components/TablePagination';
import { columnsImportacaoArquivo } from './interfaces/columnsImportacaoArquivo';
import { Col, Row } from 'antd';
import { StatCard } from '../../components/Charts/StatCard';
import { ReloadOutlined } from '@ant-design/icons';
import ButtonRefreshList from '../../components/Common/ButtonRefreshList';
import { RenderImportacaoArquivo } from './interfaces/RenderImportacaoArquivo';
import { fetchImportacaoArquivo } from '../../store/states/importacaoArquivo/actions';

function ImportacaoArquivoScreen() {
    const dispatch = useDispatch();
    const [tableKey, setTableKey] = useState(0);
    const importacaoArquivo = useSelector((state: RootState) => state.importacaoArquivo.data);
    const [importacoes, setImportacoes] = useState([]);
    const [totalImportacoes, setTotalImportacoes] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    useEffect(() => {
        dispatch(fetchImportacaoArquivo(currentPage, pageSize));
    }, [currentPage, pageSize]);

    useEffect(() => {
        if (importacaoArquivo && importacaoArquivo.importacoes) {
            setImportacoes(importacaoArquivo.importacoes);
            setTotalImportacoes(importacaoArquivo.total_importacoes);
        }
    }, [dispatch, importacaoArquivo]);

    const handleRefresh = () => {
        dispatch(fetchImportacaoArquivo(currentPage, pageSize));
        setTableKey(prevKey => prevKey + 1);
    };

    const handlePageChange = (page: number = 1, pageSize: number = 10, sortField: string = 'importacaoarquivoid', sortOrder: string = 'DESC', filters: any) => {
        setCurrentPage(page);
        setPageSize(pageSize);
        fetchImportacaoArquivo(currentPage, pageSize)
    };

    return (
        <AnimatedBanner>
            <Container>
                <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
                    <Col flex="5">
                        <StatCard loadingData={false} title="Total Importações" value={totalImportacoes} />
                    </Col>
                </Row>
                <ButtonRefreshList loading={false} icon={<ReloadOutlined />} onClick={handleRefresh} text='Recarregar' />
                <TablePagination
                    key={tableKey}
                    loadingData={false}
                    data={importacoes}
                    rowKeyId="importacaoarquivoid"
                    rowRender={RenderImportacaoArquivo()}
                    perPage={10}
                    currentPage={currentPage}
                    totalPages={importacaoArquivo.total_importacoes}
                    onChangePage={handlePageChange}
                    expand={true}
                    columns={columnsImportacaoArquivo()}
                    externalFilters={undefined} 
                    resetFilters={undefined}
                />
            </Container>
        </AnimatedBanner>
    );
}

export default ImportacaoArquivoScreen;
