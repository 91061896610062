import {
  CLEAR_GESTAO_TAXA,
  CREATE_GESTAO_TAXA,
  CREATE_GESTAO_TAXA_FAILURE,
  CREATE_GESTAO_TAXA_SUCCESS,
  FETCH_GESTAO_TAXA,
  FETCH_GESTAO_TAXA_FAILURE,
  FETCH_GESTAO_TAXA_SUCCESS,
  IMPORT_GESTAO_TAXA,
  IMPORT_GESTAO_TAXA_FAILURE,
  IMPORT_GESTAO_TAXA_SUCCESS,
  UPDATE_GESTAO_TAXA,
  UPDATE_GESTAO_TAXA_FAILURE,
  UPDATE_GESTAO_TAXA_STATUS,
  UPDATE_GESTAO_TAXA_STATUS_FAILURE,
  UPDATE_GESTAO_TAXA_STATUS_SUCCESS,
  UPDATE_GESTAO_TAXA_SUCCESS,
} from './types';

export const clearGestaoTaxa = () => ({
  type: CLEAR_GESTAO_TAXA,
});

export const fetchGestaoTaxa = () => ({
  type: FETCH_GESTAO_TAXA,
});

export const fetchGestaoTaxaSuccess = (data: any) => ({
  type: FETCH_GESTAO_TAXA_SUCCESS,
  payload: data,
});

export const fetchGestaoTaxaFailure = (error: any) => ({
  type: FETCH_GESTAO_TAXA_FAILURE,
  payload: error,
});

export const updateGestaoTaxa = (rateId: number, updatedData: any) => ({
  type: UPDATE_GESTAO_TAXA,
  payload: { rateId, updatedData },
});

export const updateGestaoTaxaSuccess = () => ({
  type: UPDATE_GESTAO_TAXA_SUCCESS,
});

export const updateGestaoTaxaFailure = (error: any) => ({
  type: UPDATE_GESTAO_TAXA_FAILURE,
  payload: error,
});

export const createGestaoTaxa = (newRate: any) => ({
  type: CREATE_GESTAO_TAXA,
  payload: {
    newRate,
  },
});

export const createGestaoTaxaSuccess = () => ({
  type: CREATE_GESTAO_TAXA_SUCCESS,
});

export const createGestaoTaxaFailure = (error: any) => ({
  type: CREATE_GESTAO_TAXA_FAILURE,
  payload: error,
});

export const importGestaoTaxa = (clienteId: any, file: File) => ({
  type: IMPORT_GESTAO_TAXA,
  payload: { clienteId, file },
});

export const importGestaoTaxaSuccess = () => ({
  type: IMPORT_GESTAO_TAXA_SUCCESS,
});

export const importGestaoTaxaFailure = (error: any) => ({
  type: IMPORT_GESTAO_TAXA_FAILURE,
  payload: error,
});

export const updateGestaoTaxaStatus = (gestaoTaxaId: any, status: any) => ({
  type: UPDATE_GESTAO_TAXA_STATUS,
  payload: { gestaoTaxaId, status },
});

export const updateGestaoTaxaStatusSuccess = () => ({
  type: UPDATE_GESTAO_TAXA_STATUS_SUCCESS,
});

export const updateGestaoTaxaStatusFailure = (error: any) => ({
  type: UPDATE_GESTAO_TAXA_STATUS_FAILURE,
  payload: error,
});
