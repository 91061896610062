export const CLEAR_GESTAO_TAXA = 'CLEAR_GESTAO_TAXA';
export const FETCH_GESTAO_TAXA = 'FETCH_GESTAO_TAXA';
export const FETCH_GESTAO_TAXA_SUCCESS = 'FETCH_GESTAO_TAXA_SUCCESS';
export const FETCH_GESTAO_TAXA_FAILURE = 'FETCH_GESTAO_TAXA_FAILURE';
export const UPDATE_GESTAO_TAXA = 'UPDATE_GESTAO_TAXA';
export const UPDATE_GESTAO_TAXA_SUCCESS = 'UPDATE_GESTAO_TAXA_SUCCESS';
export const UPDATE_GESTAO_TAXA_FAILURE = 'UPDATE_GESTAO_TAXA_FAILURE';
export const CREATE_GESTAO_TAXA = 'CREATE_GESTAO_TAXA';
export const CREATE_GESTAO_TAXA_SUCCESS = 'CREATE_GESTAO_TAXA_SUCCESS';
export const CREATE_GESTAO_TAXA_FAILURE = 'CREATE_GESTAO_TAXA_FAILURE';
export const IMPORT_GESTAO_TAXA = 'IMPORT_GESTAO_TAXA';
export const IMPORT_GESTAO_TAXA_SUCCESS = 'IMPORT_GESTAO_TAXA_SUCCESS';
export const IMPORT_GESTAO_TAXA_FAILURE = 'IMPORT_GESTAO_TAXA_FAILURE';
export const UPDATE_GESTAO_TAXA_STATUS = 'UPDATE_GESTAO_TAXA_STATUS';
export const UPDATE_GESTAO_TAXA_STATUS_SUCCESS = 'UPDATE_GESTAO_TAXA_STATUS_SUCCESS';
export const UPDATE_GESTAO_TAXA_STATUS_FAILURE = 'UPDATE_GESTAO_TAXA_STATUS_FAILURE';
