import { takeLatest, call, put } from 'redux-saga/effects';
import { changeRateApi, createRateApi, updateRateApi, gestaoTaxaApi, importRateApi } from '../../target-api/api';
import {
  createGestaoTaxaFailure,
  createGestaoTaxaSuccess,
  fetchGestaoTaxaSuccess,
  updateGestaoTaxaFailure,
  updateGestaoTaxaSuccess,
  fetchGestaoTaxa,
  fetchGestaoTaxaFailure,
  importGestaoTaxaSuccess,
  importGestaoTaxaFailure,
} from './actions';
import { message } from 'antd';
import { CREATE_GESTAO_TAXA, FETCH_GESTAO_TAXA, IMPORT_GESTAO_TAXA, UPDATE_GESTAO_TAXA, UPDATE_GESTAO_TAXA_STATUS } from './types';

export function* fetchRateRegisterSaga() {
  try {
    const data: any[] = yield call(gestaoTaxaApi);
    if (data !== undefined) {
      yield put(fetchGestaoTaxaSuccess(data));
    } else {
      message.warning('Falha ao retornar lista de taxas!');
    }
  } catch (error: any) {
    message.error('Falha ao buscar taxas.');
    yield put(fetchGestaoTaxaFailure(error.toString()));
  }
}

export function* createRateRegisterSaga(action: any) {
  try {
    const {
      payload: { newRate },
    }: { payload: { newRate: any } } = action;
    yield call(createRateApi, newRate);
    yield put(createGestaoTaxaSuccess());
    yield put(fetchGestaoTaxa());
  } catch (error) {
    yield put(createGestaoTaxaFailure(error));
  }
}

export function* updateRateRegisterSaga(action: any) {
  try {
    const { rateId, updatedData } = action.payload;
    yield call(changeRateApi, rateId, updatedData);
    yield put(updateGestaoTaxaSuccess());
    yield put(fetchGestaoTaxa());
    message.success('Taxa de contrato atualizada com sucesso!');
  } catch (error) {
    message.error(`Error updating rate register: ${error}`);
    yield put(updateGestaoTaxaFailure(error));
  }
}

export function* updateGestaoTaxaStatusSaga(action: any) {
  try {
    const { gestaoTaxaId, status } = action.payload;
    yield call(updateRateApi, gestaoTaxaId, status);
    yield put(updateGestaoTaxaSuccess());
    yield put(fetchGestaoTaxa());
    message.success('Taxa de contrato atualizada com sucesso!');
  } catch (error) {
    yield put(updateGestaoTaxaFailure(error));
  }
}

export function* importRateSaga(action: any): Generator<any, void, any> {
  try {
    const { clienteId, file } = action.payload;
    yield call(importRateApi, clienteId, file);
    yield put(importGestaoTaxaSuccess());
    yield put(fetchGestaoTaxa());
    message.success('Taxa de contrato importada com sucesso!');
  } catch (error) {
    yield put(importGestaoTaxaFailure(error));
  }
}

export function* watchRateRegisterSaga() {
  yield takeLatest(FETCH_GESTAO_TAXA, fetchRateRegisterSaga);
  yield takeLatest(CREATE_GESTAO_TAXA, createRateRegisterSaga);
  yield takeLatest(UPDATE_GESTAO_TAXA, updateRateRegisterSaga);
  yield takeLatest(IMPORT_GESTAO_TAXA, importRateSaga);
  yield takeLatest(UPDATE_GESTAO_TAXA_STATUS, updateGestaoTaxaStatusSaga);
}
