import React, { useState } from 'react';
import { Card, Form, Modal, Button, Row, Col, Upload, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { importGestaoTaxa } from '../../../store/states/gestao-taxa/actions';
import { RootState } from '../../../store/types';
import { Select } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
const { Option } = Select;

interface ModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

const ModalRateImport: React.FC<ModalProps> = ({ title, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const cliente = useSelector((state: RootState) => state.cliente.data);
  const [fileList, setFileList] = useState<any[]>([]);
  const [form] = Form.useForm();
  const cardStyle = {
    borderRadius: '10px',
  };

  const beforeUpload = (file: any) => {
    const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isXlsx) {
      message.error('Você só pode enviar arquivos .xlsx!');
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('O arquivo deve ser menor que 10MB!');
    }
    return isXlsx && isLt10M;
  };

  const handleChange = ({ fileList }: { fileList: any[] }) => setFileList(fileList);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSave = () => {
    form.validateFields().then((formData) => {
      const { cliente_id } = formData;
      const file = fileList.length ? fileList[0].originFileObj : null;
      dispatch(importGestaoTaxa(parseInt(cliente_id), file));
      handleClose();
    });
  };

  const downloadTemplate = () => {
    window.open('/planilha_taxas.xlsx', '_blank');
  };  

  return (
    <Modal
      title={title}
      visible={isOpen}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancelar
        </Button>,
        <Button style={{ backgroundColor: '#252f64' }} key="save" type="primary" onClick={handleSave}>
          Importar Agora
        </Button>,
      ]}
      width={700}
    >
      <Card style={cardStyle}>
        <Form layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item name="cliente_id" label="Cliente" rules={[{ required: true, message: 'Por favor, informe o cliente!' }]}>
                <Select
                  allowClear
                  showSearch
                  placeholder="Selecione o cliente"
                  optionFilterProp="children"
                  filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {cliente && cliente.clientes && cliente.clientes.map((cli: any) => (
                    <Option key={cli.clienteid} value={cli.clienteid}>
                      {cli.clientenome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button icon={<DownloadOutlined />} onClick={downloadTemplate}>
                  Baixar Planilha Modelo
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Upload
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  onRemove={() => setFileList([])}
                  fileList={fileList}
                  accept=".xlsx"
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Subir Arquivo Importação</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};

export default ModalRateImport;
