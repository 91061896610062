import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Container } from '../../styles/GlobalStyles';
import { useEffect, useState } from 'react';
import { fetchEmpresa, updateEmpresaNome, updateEmpresaStatus } from '../../store/states/empresa/actions';
import AnimatedBanner from '../../components/AnimatedBanner';
import TableComponent from '../../components/TableComponent';
import { columnsEmpresa } from './interfaces/columnsEmpresa';
import { Col, Input, Modal, Row } from 'antd';
import { StatCard } from '../../components/Charts/StatCard';
import { ReloadOutlined } from '@ant-design/icons';
import ButtonRefreshList from '../../components/Common/ButtonRefreshList';
import { RenderEmpresa } from './interfaces/renderEmpresa';

function EmpresaScreen() {
    const dispatch = useDispatch();
    const cliente = useSelector((state: RootState) => state.cliente.data);
    const empresa = useSelector((state: RootState) => state.empresa.data);
    const [empresas, setEmpresas] = useState([]);
    const [totalEmpresas, setTotalEmpresas] = useState(0);
    const [totalAtivas, setTotalAtivas] = useState(0);
    const [totalInativas, setTotalInativas] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingEmpresaId, setEditingEmpresaId] = useState('');
    const [newEmpresaNome, setNewEmpresaNome] = useState('');

    useEffect(() => {
        dispatch(fetchEmpresa());
    }, []);

    useEffect(() => {
        if (empresa && empresa.empresas) {
            setEmpresas(empresa.empresas);
            setTotalEmpresas(empresa.total_empresas);
            setTotalAtivas(empresa.total_ativas);
            setTotalInativas(empresa.total_inativas);
        }
    }, [dispatch, empresa]);

    const handleRefresh = () => {
        dispatch(fetchEmpresa());
    };

    const toggleStatus = (empresaId: string, status: boolean) => {
        dispatch(updateEmpresaStatus(empresaId, !status));
    };

    const showModal = (empresaId: any) => {
        setEditingEmpresaId(empresaId);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        dispatch(updateEmpresaNome(editingEmpresaId, newEmpresaNome));
        setIsModalVisible(false);
        setNewEmpresaNome('');
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setNewEmpresaNome('');
    };

    const handleChange = (e: any) => {
        setNewEmpresaNome(e.target.value);
    };

    return (
        <AnimatedBanner>
            <Container>
                <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
                    <Col flex="5">
                        <StatCard loadingData={false} title="Total Empresas" value={totalEmpresas} />
                    </Col>
                    <Col flex="5">
                        <StatCard loadingData={false} title="Ativas" value={totalAtivas} />
                    </Col>
                    <Col flex="5">
                        <StatCard loadingData={false} title="Inativas" value={totalInativas} />
                    </Col>
                </Row>
                <ButtonRefreshList loading={false} icon={<ReloadOutlined />} onClick={handleRefresh} text='Recarregar' />
                <TableComponent
                    loadingData={false}
                    data={empresas}
                    columns={columnsEmpresa(cliente.clientes)}
                    rowKeyId="empresaid"
                    rowRender={RenderEmpresa(toggleStatus, showModal)}
                    perPage={10}
                    expand={true}
                />
            </Container>
            <Modal title="Renomear Empresa" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Input placeholder="Novo nome da empresa" value={newEmpresaNome} onChange={handleChange} />
            </Modal>
        </AnimatedBanner>
    );
}

export default EmpresaScreen;
