import { Reducer } from 'redux';
import { FETCH_CLIENTE, FETCH_CLIENTE_SUCCESS, FETCH_CLIENTE_FAILTURE, UPDATE_CLIENTE_STATUS, UPDATE_CLIENTE_STATUS_SUCCESS, UPDATE_CLIENTE_STATUS_FAILURE } from './types';

const initialState = {
  loading: false,
  data: {},
  error: null,
  clientes: [],
};

export const reducer: Reducer<any, any> = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_CLIENTE:
      return { ...state, loading: true };
    case FETCH_CLIENTE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_CLIENTE_FAILTURE:
      return { ...state, error: action.payload, loading: false };
    case UPDATE_CLIENTE_STATUS:
      return { ...state, loading: true };
    case UPDATE_CLIENTE_STATUS_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_CLIENTE_STATUS_FAILURE:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
