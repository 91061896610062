import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Container } from '../../styles/GlobalStyles';
import { useEffect, useState } from 'react';
import AnimatedBanner from '../../components/AnimatedBanner';
import TableComponent from '../../components/TableComponent';
import { columnsEstabelecimento } from './interfaces/columnsEstabelecimento';
import { Col, Row } from 'antd';
import { StatCard } from '../../components/Charts/StatCard';
import { ReloadOutlined } from '@ant-design/icons';
import ButtonRefreshList from '../../components/Common/ButtonRefreshList';
import { fetchEstabelecimento, updateEstabelecimentoStatus } from '../../store/states/estabelecimento/actions';
import { RenderEstabelecimento } from './interfaces/renderEstabelecimento';

function EstabelecimentoScreen() {
    const dispatch = useDispatch();
    const cliente = useSelector((state: RootState) => state.cliente.data);
    const empresa = useSelector((state: RootState) => state.empresa.data);
    const adquirente = useSelector((state: RootState) => state.adquirente.data);
    const estabelecimento = useSelector((state: RootState) => state.estabelecimento.data);
    const [estabelecimentos, setEstabelecimentos] = useState([]);
    const [totalEstabelecimentos, setTotalEstabelecimentos] = useState(0);
    const [totalAtivos, setTotalAtivos] = useState(0);
    const [totalInativos, setTotalInativos] = useState(0);

    useEffect(() => {
        if (estabelecimento && estabelecimento.estabelecimentos) {
            setEstabelecimentos(estabelecimento.estabelecimentos);
            setTotalEstabelecimentos(estabelecimento.total_estabelecimentos);
            setTotalAtivos(estabelecimento.total_ativos);
            setTotalInativos(estabelecimento.total_inativos);
        }
    }, [dispatch, estabelecimento]);

    const handleRefresh = () => {
        dispatch(fetchEstabelecimento());
    };

    const toggleStatus = (empresaId: string, status: boolean) => {
        dispatch(updateEstabelecimentoStatus(empresaId, !status));
    };

    return (
        <AnimatedBanner>
            <Container>
                <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
                    <Col flex="5">
                        <StatCard loadingData={false} title="Total Estabelecimentos" value={totalEstabelecimentos} />
                    </Col>
                    <Col flex="5">
                        <StatCard loadingData={false} title="Ativos" value={totalAtivos} />
                    </Col>
                    <Col flex="5">
                        <StatCard loadingData={false} title="Inativos" value={totalInativos} />
                    </Col>
                </Row>
                <ButtonRefreshList loading={false} icon={<ReloadOutlined />} onClick={handleRefresh} text='Recarregar' />
                <TableComponent
                    loadingData={false}
                    data={estabelecimentos}
                    columns={columnsEstabelecimento(cliente.clientes, empresa.empresas, adquirente)}
                    rowKeyId="estabelecimentoid"
                    rowRender={RenderEstabelecimento(cliente.clientes, empresa.empresas, adquirente, toggleStatus)}
                    perPage={10}
                    expand={true}
                />
            </Container>
        </AnimatedBanner>
    );
}

export default EstabelecimentoScreen;
