import api from '../../../services/axios';

export async function notificationApi(empresa_id: number): Promise<any> {
    const response = await api.get('/notificacoes/all', {
        params: { empresa_id },
    });
    return response.data;
}

export async function updateNotificationApi(notification_id: number, status: number): Promise<any> {
    const response = await api.post(`/notificacoes/update/${notification_id}/${status}`);
    return response.data;
}
