import { Card, Col, Row, Tag } from 'antd';
import {
    AlertOutlined,
    BranchesOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    CloudSyncOutlined,
    KeyOutlined,
    PaperClipOutlined,
    UserOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import ButtonRefreshList from '../../../components/Common/ButtonRefreshList';

export const RenderUsuario = (
    toggleStatus: (usuarioId: string, status: boolean) => void,
    toggleSyncUsuarioEmpresa: (usuarioemail: string) => void,
    openPermissionsModal: (usuarioId: string, permissions: []) => void,
    toggleRecuperarSenha: (usuarioId: string) => void
) => (record: any) => {
    return (
        <Card bordered={false} style={{ marginTop: 16, marginBottom: 16, cursor: 'pointer' }}>
            <Row gutter={16}>
                <Col span={6}>
                    <div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
                        {record.usuariofoto === null || record.usuariofoto === '' ? <UserOutlined style={{ fontSize: '20px', marginRight: 10 }} /> :
                            <img src={record.usuariofoto} alt="Avatar do usuário" style={{ width: '55px', height: '55px', borderRadius: '15%', marginRight: 10 }} />}
                    </div>
                    <div>
                        <ClockCircleOutlined style={{ marginRight: 10 }} />
                        <span>Data de criação: </span>
                        <strong>{moment(record.usuariocreatedat).format('DD/MM/YYYY')}</strong>
                    </div>
                    <div>
                        <PaperClipOutlined style={{ marginRight: 10 }} />
                        <span>Nome: </span>
                        <strong>{record.usuarionome}</strong>
                    </div>
                    <div>
                        <PaperClipOutlined style={{ marginRight: 10 }} />
                        <span>Username: </span>
                        <strong>{record.usuariousernome}</strong>
                    </div>
                    <div>
                        <PaperClipOutlined style={{ marginRight: 10 }} />
                        <span>E-mail: </span>
                        <strong>{record.usuarioemail}</strong>
                    </div>
                    <div>
                        <BranchesOutlined style={{ marginRight: 10, marginTop: 15, marginBottom: 15 }} />
                        <span>Permissões: </span>
                        <div>
                            {record.permissions.length > 0 ? record.permissions.map((permission: any) => (
                                <Tag color="blue" key={permission}>{permission}</Tag>
                            )) : <Tag color="gray">Padrão</Tag>}
                        </div>
                    </div>
                    <div style={{ marginTop: 15, marginBottom: 15 }}>
                        <AlertOutlined style={{ marginRight: 10 }} />
                        <strong>Ações</strong>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <ButtonRefreshList loading={false} icon={record.usuariostatus ? <CloseOutlined /> : <CheckOutlined />} onClick={() => toggleStatus(record.usuarioid, record.usuariostatus)} text={record.usuariostatus ? 'Desativar' : 'Ativar'} />
                        <ButtonRefreshList loading={false} icon={<CloudSyncOutlined />} onClick={() => toggleSyncUsuarioEmpresa(record.usuarioemail)} text='Sincronizar Empresas' />
                        <ButtonRefreshList loading={false} icon={<BranchesOutlined />} onClick={() => openPermissionsModal(record.usuarioid, record.permissions)} text='Alterar Permissões' />
                        <ButtonRefreshList loading={false} icon={<KeyOutlined />} onClick={() => toggleRecuperarSenha(record.usuarioemail)} text='Recuperar Senha' />
                    </div>
                </Col>
            </Row>
        </Card >
    );
};
