import api from '../../../services/axios';

export async function clientesApi(): Promise<any> {
    const response = await api.get('/clientes');
    return response.data;
}

export async function updateClienteApi(clienteId: number[] | number, status: boolean): Promise<any> {
    const params = {
        cliente_id: clienteId,
        status: status
    };
    const response = await api.put('/clientes/update', params);
    return response.data;
}
