import { takeLatest, call, put } from 'redux-saga/effects';
import { estabelecimentoApi, updateEstabelecimentoApi } from '../../target-api/api';
import { fetchEstabelecimento, fetchEstabelecimentoFailure, fetchEstabelecimentoSuccess } from './actions';
import { FETCH_ESTABELECIMENTO, UPDATE_ESTABELECIMENTO_STATUS } from './types';
import { message } from 'antd';

export function* fetchEstabelecimentoSaga() {
  try {
    const data: any[] = yield call(estabelecimentoApi);
    if (data !== undefined) {
      yield put(fetchEstabelecimentoSuccess(data));
    } else {
      message.warning('Falha ao retornar lista de estabelecimentos!');
    }
  } catch (error: any) {
    message.error('Falha ao buscar estabelecimentos.');
    yield put(fetchEstabelecimentoFailure(error.toString()));
  }
}

export function* updateEstabelecimentoStatusSaga(action: any) {
  try {
    const { estabelecimentoId, status } = action.payload;
    yield call(updateEstabelecimentoApi, estabelecimentoId, status);
    message.success('Status atualizado com sucesso!');
    yield put(fetchEstabelecimento());
  } catch (error: any) {
    message.error('Falha ao atualizar status do estabelecimento.');
  }
}

export function* watchEstabelecimentoSaga() {
  yield takeLatest(FETCH_ESTABELECIMENTO, fetchEstabelecimentoSaga);
  yield takeLatest(UPDATE_ESTABELECIMENTO_STATUS, updateEstabelecimentoStatusSaga);
}
