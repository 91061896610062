import React, { useEffect, useState } from 'react';
import { TablePaginationProps } from './interfaces';
import { StyledTable } from './styled';
import CustomLoading from '../CustomLoading';

const TablePagination: React.FC<TablePaginationProps> = ({
  loadingData,
  data,
  columns,
  rowKeyId,
  rowRender,
  perPage,
  expand,
  currentPage,
  totalPages,
  onChangePage,
  externalFilters,
  resetFilters,
  updateFilters }) => {
  const [sortField, setSortField] = useState<any>('');
  const [sortOrder, setSortOrder] = useState<any>('');
  const [currentFilters, setCurrentFilters] = useState<any>({});
  const [expandedKeys, setExpandedKeys] = useState<any>([]);

  useEffect(() => {
    if (resetFilters) {
      setCurrentFilters({});
      setSortField('');
      setSortOrder('');
      setExpandedKeys([]);
    }
  }, [resetFilters]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { field, order } = sorter;
    setSortField(field);
    setSortOrder(order);
    const combinedFilters = { ...externalFilters, ...filters };
    setCurrentFilters(combinedFilters);
    updateFilters(combinedFilters);
    onChangePage(pagination.current, pagination.pageSize, field, order, combinedFilters);
  };

  const handleExpand = (record: any) => {
    const key = record[rowKeyId];
    const keys = expandedKeys.includes(key) ? expandedKeys.filter((k: any) => k !== key) : [...expandedKeys, key];
    setExpandedKeys(keys);
  };

  const expandableConfig = expand
    ? {
      expandedRowRender: rowRender,
      expandedRowKeys: expandedKeys,
      onExpand: (_: any, record: any) => handleExpand(record),
    }
    : undefined;

  return (
    <StyledTable
      loading={{
        spinning: loadingData,
        indicator: <CustomLoading />
      }}
      size="small"
      expandable={expandableConfig}
      columns={columns}
      dataSource={data}
      rowKey={rowKeyId}
      showSorterTooltip={false}
      sortDirections={sortOrder ? ['ascend', 'descend'] : undefined}
      pagination={{
        total: totalPages,
        current: currentPage,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '15'],
        defaultPageSize: perPage ? perPage : 10,
        onChange: (page: any, pageSize: any) => onChangePage(page, pageSize, sortField, sortOrder, currentFilters),
      }}
      onChange={handleTableChange}
      scroll={{ x: 'max-content' }}
      onRow={(record) => ({
        onClick: () => handleExpand(record),
        style: { cursor: 'pointer' },
      })}
      style={{ marginTop: 10 }}
    />
  );
};

export default TablePagination;
