import { Card, Col, Row } from 'antd';
import {
    AlertOutlined,
    BankOutlined,
    CalendarOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    FieldNumberOutlined,
    PaperClipOutlined,
    PercentageOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updateClienteStatus } from '../../../store/states/cliente/actions';
import ButtonRefreshList from '../../../components/Common/ButtonRefreshList';

export const RenderCliente = (record: any) => {
    const dispatch = useDispatch();
    const toggleStatus = (clienteId: string, status: boolean) => {
        dispatch(updateClienteStatus(clienteId, !status));
    };

    return (
        <Card bordered={false} style={{ marginTop: 16, marginBottom: 16, cursor: 'pointer' }}>
            <Row gutter={16}>
                <Col span={6}>
                    <div>
                        <ClockCircleOutlined style={{ marginRight: 10 }} />
                        <span>Data de criação: </span>
                        <strong>{moment(record.clientecreatedat).format('DD/MM/YYYY')}</strong>
                    </div>
                    <div>
                        <BankOutlined style={{ marginRight: 10 }} />
                        <span>Nome: </span>
                        <strong>{record.clientenome}</strong>
                    </div>
                    <div>
                        <FieldNumberOutlined style={{ marginRight: 10 }} />
                        <span>Identificador: </span>
                        <strong>{record.clienteidentificador}</strong>
                    </div>
                    <div>
                        <PaperClipOutlined style={{ marginRight: 10 }} />
                        <span>E-mail: </span>
                        <strong>{record.clienteemail}</strong>
                    </div>
                    <div>
                        <CalendarOutlined style={{ marginRight: 10 }} />
                        <span>Documento matriz: </span>
                        <strong>{record.clientedocumento}</strong>
                    </div>
                    <div>
                        <PercentageOutlined style={{ marginRight: 10 }} />
                        <span>Telefone: </span>
                        <strong>{record.clientetelefone}</strong>
                    </div>
                    <div style={{ marginTop: 15, marginBottom: 15 }}>
                        <AlertOutlined style={{ marginRight: 10 }} />
                        <strong>Ações</strong>
                    </div>
                    <ButtonRefreshList loading={false} icon={record.clientestatus ? <CloseOutlined /> : <CheckOutlined />} onClick={() => toggleStatus(record.clienteid, record.clientestatus)} text={record.clientestatus ? 'Desativar' : 'Ativar'} />
                </Col>
            </Row>
        </Card >
    );
};
