import React, { ReactNode } from 'react';
import { ButtonRefreshListStyled } from './styled';

interface ButtonRefreshListProps {
    onClick: () => void;
    text: string;
    icon: React.ReactNode;
    loading: boolean;
    children?: ReactNode;
}

const ButtonRefreshList: React.FC<ButtonRefreshListProps> = ({ loading, icon, onClick, text }) => {
    return (
        <ButtonRefreshListStyled onClick={onClick} icon={icon} loading={loading}>
            {text}
        </ButtonRefreshListStyled>
    );
};

export default ButtonRefreshList;