import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

export const columnsUsuario = (clientes: any[]): ColumnsType<any> => {
  const clienteOptions = clientes
    .map(cliente => ({
      text: cliente.clientenome,
      value: cliente.clienteid,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));
  const columns: ColumnsType<any> = [
    {
      title: 'Data de Criação',
      dataIndex: 'usuariocreatedat',
      key: 'usuariocreatedat',
      sorter: (a, b) => moment(a.usuariocreatedat).unix() - moment(b.usuariocreatedat).unix(),
      render: text => <div>{moment(text).format('DD/MM/YYYY HH:mm:ss')}</div>,
    },
    {
      title: 'Nome',
      dataIndex: 'usuarionome',
      key: 'usuarionome',
      sorter: (a, b) => a.usuarionome.localeCompare(b.usuarionome),
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return record.usuarionome.toLowerCase().includes(value.toLowerCase());
      },
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: 'E-mail',
      dataIndex: 'usuarioemail',
      key: 'usuarioemail',
      sorter: (a, b) => a.usuarioemail.localeCompare(b.usuarioemail),
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return record.usuarioemail.toLowerCase().includes(value.toLowerCase());
      },
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: 'Cliente',
      dataIndex: 'usuarioclienteid',
      key: 'usuarioclienteid',
      filters: clienteOptions,
      onFilter: (value, record) => record.usuarioclienteid === value,
      render: (text: string, record: any) => {
        const cliente = clientes.find(c => c.clienteid === text);
        return <div>{cliente ? cliente.clientenome : 'Cliente não encontrado'}</div>;
      },
    },
    {
      title: 'Situação',
      dataIndex: 'usuariostatus',
      key: 'usuariostatus',
      sorter: (a, b) => (a.usuariostatus === b.usuariostatus ? 0 : a.usuariostatus ? -1 : 1),
      render: status => <Tag color={status ? 'green' : 'red'}>{status ? 'Ativo' : 'Inativo'}</Tag>,
      filters: [
        { text: 'Ativo', value: true },
        { text: 'Inativo', value: false }
      ],
      onFilter: (value, record) => record.usuariostatus === value,
    }
  ];
  return columns;
};
