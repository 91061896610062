import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Container } from '../../styles/GlobalStyles';
import { useEffect, useState } from 'react';
import AnimatedBanner from '../../components/AnimatedBanner';
import TableComponent from '../../components/TableComponent';
import { columnsUsuarioEvento } from './interfaces/columnsUsuarioEvento';
import { Col, Row } from 'antd';
import { StatCard } from '../../components/Charts/StatCard';
import { ReloadOutlined } from '@ant-design/icons';
import ButtonRefreshList from '../../components/Common/ButtonRefreshList';
import { fetchUsuario, fetchUsuarioEvento } from '../../store/states/usuario/actions';

function UsuarioEventoScreen() {
    const dispatch = useDispatch();
    const usuario = useSelector((state: RootState) => state.usuario);
    const cliente = useSelector((state: RootState) => state.cliente.data);
    const [eventos, setEventos] = useState([]);
    const [usuarios, setUsuarios] = useState([]);

    useEffect(() => {
        dispatch(fetchUsuario());
        dispatch(fetchUsuarioEvento());
    }, []);

    useEffect(() => {
        if (usuario && usuario.eventos.eventos) {
            setEventos(usuario.eventos.eventos);
            setUsuarios(usuario.data.usuarios);
        }
    }, [dispatch, usuario]);

    const handleRefresh = () => {
        dispatch(fetchUsuarioEvento());
    };

    return (
        <AnimatedBanner>
            <Container>
                <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
                    <Col flex="5">
                        <StatCard loadingData={false} title="Eventos" value={eventos.length} />
                    </Col>
                </Row>
                <ButtonRefreshList loading={false} icon={<ReloadOutlined />} onClick={handleRefresh} text='Recarregar' />
                <TableComponent
                    loadingData={false}
                    data={eventos}
                    columns={columnsUsuarioEvento(usuarios, cliente.clientes)}
                    rowKeyId="usuarioeventoid"
                    perPage={10}
                    expand={true}
                />
            </Container>
        </AnimatedBanner>
    );
}

export default UsuarioEventoScreen;
