import api from '../../../services/axios';
import { message } from 'antd';

export async function loginApi(username: string, password: string, customer: string): Promise<any> {
    const response = await api.post<any>('/login', {
        username,
        password,
        customer,
    });
    if (response.data.code !== 401) {
        message.success(response.data.message);
    } else {
        message.error(response.data.message);
    }
    return response.data;
}
