import { useState, useEffect, useMemo } from 'react';
import { StyledSelect } from './styled';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/types';
const { Option } = Select;

function CustomSelect(props: {
  data: any;
  selectedCompanyID: number | number[];
  onChange: (selectedItems: number[]) => void;
}) {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const vendaLoading = useSelector((state: RootState) => state.venda.loading);
  const pagamentoLoading = useSelector((state: RootState) => state.pagamento.loading);

  useEffect(() => {
    if (props.selectedCompanyID) {
      setSelectedItems(Array.isArray(props.selectedCompanyID) ? props.selectedCompanyID : [props.selectedCompanyID]);
    }
  }, [props.selectedCompanyID]);

  const handleChange = (value: number[], option: any) => {
    setSelectedItems(value);
    props.onChange(value);
  };

  const options = useMemo(() => {
    return (
      props.data &&
      props.data
        .sort((a: any, b: any) => a.empresanome.localeCompare(b.empresanome))
        .map((option: any) => (
          <Option key={option.empresaid} value={option.empresaid}>
            {option.empresanome}
          </Option>
        ))
    );
  }, [props.data]);

  return (
    <StyledSelect
      disabled={vendaLoading || pagamentoLoading}
      mode="multiple"
      placeholder="Selecionar Empresa(s)"
      value={selectedItems}
      onChange={(value, option) => handleChange(value as number[], option)}
      style={{ minWidth: 200, maxWidth: 0 }}
      showSearch
      filterOption={(input, option) =>
        option!.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      {options}
    </StyledSelect>
  );
}

export default CustomSelect;
