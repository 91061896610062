import { FETCH_ESTABELECIMENTO, FETCH_ESTABELECIMENTO_SUCCESS, FETCH_ESTABELECIMENTO_FAILURE, UPDATE_ESTABELECIMENTO_STATUS, UPDATE_ESTABELECIMENTO_STATUS_SUCCESS, UPDATE_ESTABELECIMENTO_STATUS_FAILURE } from './types';

export const fetchEstabelecimento = () => ({
  type: FETCH_ESTABELECIMENTO,
});

export const fetchEstabelecimentoSuccess = (data: any) => ({
  type: FETCH_ESTABELECIMENTO_SUCCESS,
  payload: data,
});

export const fetchEstabelecimentoFailure = (error: any) => ({
  type: FETCH_ESTABELECIMENTO_FAILURE,
  payload: error,
});

export const updateEstabelecimentoStatus = (estabelecimentoId: string, status: boolean) => ({
  type: UPDATE_ESTABELECIMENTO_STATUS,
  payload: { estabelecimentoId, status }
});

export const updateEstabelecimentoStatusSuccess = () => ({
  type: UPDATE_ESTABELECIMENTO_STATUS_SUCCESS
});

export const updateEstabelecimentoStatusFailure = (error: any) => ({
  type: UPDATE_ESTABELECIMENTO_STATUS_FAILURE,
  payload: error,
});
