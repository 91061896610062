import { Card, Col, Row } from 'antd';
import {
    AlertOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    ExperimentOutlined,
    FieldNumberOutlined,
    PushpinOutlined,
    ShoppingCartOutlined,
    WalletOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import ButtonRefreshList from '../../../components/Common/ButtonRefreshList';

interface RecordType {
    estabelecimentocreatedat: string;
    estabelecimentomatriz: string;
    estabelecimentonumero: string;
    estabelecimentoconfig?: string;
    estabelecimentoclienteid?: string;
    estabelecimentoempresaid?: string;
    estabelecimentoadquirenteid?: string;
    estabelecimentostatus: boolean;
    estabelecimentoid: string;
}

export const RenderEstabelecimento = (
    clientes: any,
    empresas: any,
    adquirentes: any,
    toggleStatus: (empresaId: string, status: boolean) => void
) => (record: RecordType) => {
    return (
        <Card bordered={false} style={{ marginTop: 16, marginBottom: 16, cursor: 'pointer' }}>
            <Row gutter={16}>
                <Col span={6}>
                    <div>
                        <ClockCircleOutlined style={{ marginRight: 10 }} />
                        <span>Data de criação: </span>
                        <strong>{moment(record.estabelecimentocreatedat).format('DD/MM/YYYY')}</strong>
                    </div>
                    <div>
                        <FieldNumberOutlined style={{ marginRight: 10 }} />
                        <span>N° Estabelecimento Matriz: </span>
                        <strong>{record.estabelecimentomatriz}</strong>
                    </div>
                    <div>
                        <FieldNumberOutlined style={{ marginRight: 10 }} />
                        <span>N° Estabelecimento: </span>
                        <strong>{record.estabelecimentonumero}</strong>
                    </div>
                    <div>
                        <PushpinOutlined style={{ marginRight: 10 }} />
                        <span>Cliente: </span>
                        <strong>{
                            clientes.find((cliente: any) => cliente.clienteid === record.estabelecimentoclienteid)?.clientenome || 'Não encontrado'
                        }</strong>
                    </div>
                    <div>
                        <WalletOutlined style={{ marginRight: 10 }} />
                        <span>Empresa: </span>
                        <strong>{
                            empresas.find((empresa: any) => empresa.empresaid === record.estabelecimentoempresaid)?.empresanome || 'Não encontrado'
                        }</strong>
                    </div>
                    <div>
                        <ShoppingCartOutlined style={{ marginRight: 10 }} />
                        <span>Adquirente: </span>
                        <strong>{
                            adquirentes.find((adquirente: any) => adquirente.adquirenteid === record.estabelecimentoadquirenteid)?.adquirentenome || 'Não encontrado'
                        }</strong>
                    </div>
                    <div>
                        <ExperimentOutlined style={{ marginRight: 10 }} />
                        <span>Configuração: </span>
                        <strong>{record.estabelecimentoconfig ? record.estabelecimentoconfig : 'N/A'}</strong>
                    </div>
                    <div style={{ marginTop: 15, marginBottom: 15 }}>
                        <AlertOutlined style={{ marginRight: 10 }} />
                        <strong>Ações</strong>
                    </div>
                    <ButtonRefreshList loading={false} icon={record.estabelecimentostatus ? <CloseOutlined /> : <CheckOutlined />} onClick={() => toggleStatus(record.estabelecimentoid, record.estabelecimentostatus)} text={record.estabelecimentostatus ? 'Desativar' : 'Ativar'} />
                </Col>
            </Row>
        </Card >
    );
};
