import { Reducer } from 'redux';
import {
  SEND_PROCESS_QUEUE,
  SEND_PROCESS_QUEUE_SUCCESS,
  SEND_PROCESS_QUEUE_FAILURE,
} from './types';

export const processState = {
  data: [],
  loading: false,
  error: null
};

export const reducer: Reducer<any, any> = (state = processState, action: any) => {
  switch (action.type) {
    case SEND_PROCESS_QUEUE:
      return { ...state, loading: true };
    case SEND_PROCESS_QUEUE_SUCCESS:
      return { ...state, loading: false, data: action.payload || [] };
    case SEND_PROCESS_QUEUE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
