import { Reducer } from 'redux';
import { FETCH_ESTABELECIMENTO_SUCCESS, FETCH_ESTABELECIMENTO_FAILURE, FETCH_ESTABELECIMENTO, UPDATE_ESTABELECIMENTO_STATUS, UPDATE_ESTABELECIMENTO_STATUS_SUCCESS, UPDATE_ESTABELECIMENTO_STATUS_FAILURE } from './types';

const initialState = {
  loading: false,
  data: {},
  error: null
};

export const reducer: Reducer<any, any> = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_ESTABELECIMENTO:
      return { ...state, loading: true };
    case FETCH_ESTABELECIMENTO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_ESTABELECIMENTO_FAILURE:
      return { ...state, error: action.payload };
    case UPDATE_ESTABELECIMENTO_STATUS:
      return { ...state, loading: true };
    case UPDATE_ESTABELECIMENTO_STATUS_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_ESTABELECIMENTO_STATUS_FAILURE:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
