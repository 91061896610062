/*************************************/
/*      Imagens das adquirentes      */
/*************************************/
import cardblank from '../assets/acquirers/cardblank.png';
import adyen from '../assets/acquirers/adyen.png';
import banese from '../assets/acquirers/banese.png';
import banescard from '../assets/acquirers/banescard.png';
import banricompras from '../assets/acquirers/banricompras.png';
import cielo from '../assets/acquirers/cielo.png';
import getnet from '../assets/acquirers/getnet.png';
import redecard from '../assets/acquirers/rede.png';
import safrapay from '../assets/acquirers/safrapay.png';
import sodexo from '../assets/acquirers/sodexo.png';
import stone from '../assets/acquirers/stone.png';
import pagseguro from '../assets/acquirers/pagseguro.png';
import bin from '../assets/acquirers/bin.png';
import ifood from '../assets/acquirers/ifood.png';
import ticket from '../assets/acquirers/ticket.png';
import vr from '../assets/acquirers/vr.png';
import benvisavale from '../assets/acquirers/benvisavale.png';
import valecard from '../assets/acquirers/valecard.png';
import tricard from '../assets/acquirers/tricard.png';
import alelo from '../assets/acquirers/alelo.png';
import tripag from '../assets/acquirers/tripag.png';
import sicredi from '../assets/acquirers/sicredi.png';
import ecxcard from '../assets/acquirers/ecxcard.png';
import policard from '../assets/acquirers/policard.png';
import bigcard from '../assets/acquirers/bigcard.png';
import caixapagamentos from '../assets/acquirers/caixapagamentos.svg';
import sipag from '../assets/acquirers/sipag.png';
import c6pay from '../assets/acquirers/c6pay.png';
import cabal from '../assets/acquirers/cabal.png';
import credsystem from '../assets//acquirers/credsystem.png';
import fortbrasil from '../assets/acquirers/fortbrasil.png';
import greencard from '../assets/acquirers/greencard.png';
import picpay from '../assets/acquirers/picpay.svg';
import senff from '../assets/acquirers/senff.svg';
import adiq from '../assets/acquirers/adiq.svg';
import dmcard from '../assets/acquirers/dmcard.svg';
import binfiserv from '../assets/acquirers/binfiserv.png';
import interpag from '../assets/acquirers/interpag.png';

/************************************/
/*        Imagens dos bancos        */
/************************************/
import bancoDesconhecido from '../assets/banks/desconhecido.png';
import bancoDoBrasilImage from '../assets/banks/bb.png';
import bancoobImage from '../assets/banks/bancoob.png';
import santanderImage from '../assets/banks/santander.png';
import bancoCaixaImage from '../assets/banks/caixa.png';
import bancoStoneImage from '../assets/banks/stonebank.png';
import bancoInter from '../assets/banks/inter.png';
import bancoTopazio from '../assets/banks/topazio.png';
import bancoBS2 from '../assets/banks/bs2.png';
import bancoBradesco from '../assets/banks/bradesco.png';
import bancoABCBrasil from '../assets/banks/abc.png';
import bancoBMG from '../assets/banks/bmg.png';
import bancoItau from '../assets/banks/itau.png';
import bancoMercantilDoBrasil from '../assets/banks/mercantil.png';
import bancoSafra from '../assets/banks/safra.png';
import bancoRural from '../assets/banks/rural.png';
import bancoTriangulo from '../assets/banks/triangulo.png';
import bancoSofisa from '../assets/banks/sofisa.png';
import bancoDaycoval from '../assets/banks/daycoval.png';
import bancoSemear from '../assets/banks/semear.png';
import bancoSicredi from '../assets/banks/sicredi.png';

/************************************/
/*      Imagens das bandeiras       */
/************************************/
import amex from '../assets/flags/amex.png';
import visa from '../assets/flags/visa.png';
import mastercard from '../assets/flags/mastercard.png';
import elo from '../assets/flags/elo.png';
import americanExpress from '../assets/flags/amex.png';
import hipercard from '../assets/flags/hipercard.png';
import aleloFlag from '../assets/flags/alelo.png';
import vrAlimentacao from '../assets/flags/vr_alimentacao.png';
import vrRefeicao from '../assets/flags/vr_refeicao.png';
import pix from '../assets/flags/pix.png';
import emptyFlag from '../assets/global/emptyflag.png';
import ecxCardFlag from '../assets/flags/ecxcard.png';
import benvisa from '../assets/flags/benvisa.png';
import sodexoFlag from '../assets/flags/sodexo.png';
import valecardFlag from '../assets/flags/valecard.png';
import diners from '../assets/flags/diners.png';
import ticketAlimentacao from '../assets/flags/ticket_alimentacao.png';
import ticketRefeicao from '../assets/flags/ticket_refeicao.png';
import eloRefeicao from '../assets/flags/elo_refeicao.png';
import policardFlag from '../assets/flags/policard.png';
import bigcardFlag from '../assets/flags/bigcard.png';
import tricardFlag from '../assets/flags/tricard.png';
import hiper from '../assets/flags/hiper.svg';
import banescardFlag from '../assets/flags/banescard.svg';
import agiplanFlag from '../assets/flags/agiplan.png';
import discoverFlag from '../assets/flags/discover.png';
import unionPay from '../assets/flags/unionpay.png';

/*************************************/
/*            Utilitários            */
/*************************************/
import { adjustBrightness } from '../utils/validateBarChart';

export const bancoImagens: any = {
  1: bancoDesconhecido,
  2: bancoDoBrasilImage,
  3: santanderImage,
  4: bancoobImage,
  5: bancoCaixaImage,
  6: bancoStoneImage,
  7: bancoInter,
  8: bancoTopazio,
  9: bancoBS2,
  10: bancoBradesco,
  11: bancoABCBrasil,
  12: bancoBMG,
  13: bancoItau,
  14: bancoMercantilDoBrasil,
  15: bancoSafra,
  16: bancoRural,
  17: bancoTriangulo,
  18: bancoSofisa,
  19: bancoDaycoval,
  20: bancoSemear,
  21: bancoSicredi,
};

export const adquirenteColors = {
  GetNet: adjustBrightness('#A4DE02', 1.1),
  Cielo: adjustBrightness('#76C802', 0.9),
  Stone: adjustBrightness('#3fb545', 1.4),
  SafraPay: adjustBrightness('#49A3D0', 0.9),
  PagSeguro: adjustBrightness('#69d1a7', 0.9),
  Adyen: adjustBrightness('#49C830', 0.8),
  Bin: adjustBrightness('#3883c9', 1.1),
  Ifood: adjustBrightness('#A4DE02', 1.1),
  Tripag: adjustBrightness('#76C802', 0.9),
  Ticket: adjustBrightness('#03A9F4', 0.8),
  Sodexo: adjustBrightness('#E8EEF9', 0.8),
  VR: adjustBrightness('#C9E437', 0.5),
  BenVisaVale: adjustBrightness('#252f64', 0.3),
  Alelo: adjustBrightness('#C9E437', 0.6),
  SiCredi: adjustBrightness('#83d67c', 0.9),
  ECXCard: adjustBrightness('#4cadda', 0.9),
  Valecard: adjustBrightness('#56bff0', 0.9),
  Sipag: adjustBrightness('#63d894', 0.9),
  BigCard: adjustBrightness('#5f90a7', 0.9),
  PoliCard: adjustBrightness('#626680', 0.9),
  Rede: adjustBrightness('#03A9F4', 0.9),
  InterPag: adjustBrightness('#ff8700', 1.0),
  'Redecard': adjustBrightness('#03A9F4', 0.9),
  'C6 Pay': adjustBrightness('#afbdba', 1.1),
  'Ben Visa Vale': adjustBrightness('#5dccab', 0.9),
  'Caixa Pagamentos': adjustBrightness('#5fb7da', 1.0),
  'Bin Fiserv': adjustBrightness('#ff8700', 1.0),
};

export const adquirenteNomeParaImagem: any = {
  desconhecido: cardblank,
  getnet,
  cielo,
  adyen,
  banese,
  banescard,
  'rede': redecard,
  redecard,
  safrapay,
  picpay,
  sodexo,
  banricompras,
  stone,
  pagseguro,
  bin,
  tripag,
  ifood,
  ticket,
  vr,
  alelo,
  sicredi,
  ecxcard,
  valecard,
  tricard,
  sipag,
  bigcard,
  policard,
  cabal,
  credsystem,
  fortbrasil,
  greencard,
  senff,
  adiq,
  dmcard,
  c6pay,
  caixapagamentos,
  benvisavale,
  binfiserv,
  interpag
};

export const bancoIDParaImagens: any = {
  0: emptyFlag,
  33: santanderImage,
  1: bancoDoBrasilImage,
  756: bancoobImage,
  104: bancoCaixaImage,
  197: stone,
  77: bancoInter,
  82: bancoTopazio,
  2218: bancoBS2,
  237: bancoBradesco,
  246: bancoABCBrasil,
  318: bancoBMG,
  341: bancoItau,
  389: bancoMercantilDoBrasil,
  422: bancoSafra,
  453: bancoRural,
  634: bancoTriangulo,
  637: bancoSofisa,
  707: bancoDaycoval,
  743: bancoSemear,
  748: bancoSicredi,
  336: c6pay,
};

export const bandeiraNomeParaImagens: any = {
  desconhecida: emptyFlag,
  nao_informado: emptyFlag,
  amex,
  visa,
  mastercard,
  americanExpress,
  elo,
  hipercard,
  pix,
  cabal,
  diners,
  sodexo: sodexoFlag,
  ecxcard: ecxCardFlag,
  valecard: valecardFlag,
  bigcard: bigcardFlag,
  policard: policardFlag,
  tricard: tricardFlag,
  hiper: hiper,
  agiplan: agiplanFlag,
  'discover': discoverFlag,
  'alelo': aleloFlag,
  'banescard': banescardFlag,
  'hiper/hipercard': hipercard,
  'alelo alimentação': aleloFlag,
  'alelo refeição': aleloFlag,
  'alelo multibeneficios': aleloFlag,
  'vr alimentação': vrAlimentacao,
  'vr refeição': vrRefeicao,
  'vr benefícios': vr,
  'ticket': ticket,
  'ticket alimentação': ticketAlimentacao,
  'ticket refeição': ticketRefeicao,
  'elo refeição': eloRefeicao,
  'não cadastrada': emptyFlag,
  'não informada': emptyFlag,
  'sodexo refeição': sodexoFlag,
  'sodexo alimentação': sodexoFlag,
  'sodexo gift pass': sodexoFlag,
  'benvisa alimentação': benvisa,
  'banricompras': banricompras,
  'unionpay': unionPay
};

export const bandeiraColors = {
  Desconhecida: adjustBrightness('#e6e6e6', 0.9),
  Amex: adjustBrightness('#83a7c9', 1.1),
  Visa: adjustBrightness('#54e6ae', 1.1),
  Mastercard: adjustBrightness('#7179ee', 0.9),
  Elo: adjustBrightness('#5adb1e', 0.9),
  Pix: adjustBrightness('#99df57', 0.9),
  Cabal: adjustBrightness('#5557c9', 0.9),
  Hiper: adjustBrightness('#66d390', 0.9),
  Ticket: adjustBrightness('#5adfcd', 0.9),
  Sodexo: adjustBrightness('#8691ce', 0.9),
  ECXCard: adjustBrightness('#4cadda', 0.9),
  Hipercard: adjustBrightness('#aa92be', 0.9),
  Valecard: adjustBrightness('#56bff0', 0.9),
  Diners: adjustBrightness('#69e0a9', 0.9),
  'discover': adjustBrightness('#ff8700', 1.0),
  'banescard': adjustBrightness('#5bb877', 0.9),
  'banricompras': adjustBrightness('#5bb877', 0.9),
  'C6 Pay': adjustBrightness('#b5bd4d', 0.9),
  'BenVisa Alimentação': adjustBrightness('#7680b4', 0.9),
  'Hiper/Hipercard': adjustBrightness('#aa92be', 0.9),
  'Alelo Alimentação': adjustBrightness('#93c06c', 0.9),
  'Alelo Multibeneficios': adjustBrightness('#badb9d', 0.9),
  'Alelo Refeição': adjustBrightness('#93c06c', 0.9),
  'VR Alimentação': adjustBrightness('#93c06c', 0.9),
  'VR Refeição': adjustBrightness('#93c06c', 0.9),
  'Ticket Alimentação': adjustBrightness('#75d2e9', 0.9),
  'Ticket Refeição': adjustBrightness('#67b5e2', 0.9),
  'Elo Refeição': adjustBrightness('#b2f862', 0.9),
  'Sodexo Alimentação': adjustBrightness('#819e67', 0.9),
  'Sodexo Refeição': adjustBrightness('#7d9e60', 0.9),
  'Não cadastrada': adjustBrightness('#e6e6e6', 0.9),
  'Não informado': adjustBrightness('#e6e6e6', 0.9),
  'Não informada': adjustBrightness('#e6e6e6', 0.9),
};

export const modalidades = [
  { id: 1, name: 'Não Informado' },
  { id: 2, name: 'Débito à vista' },
  { id: 3, name: 'Crédito à vista' },
  { id: 4, name: 'Crédito parcelado' },
  { id: 5, name: 'Voucher' },
  { id: 6, name: 'Pix' },
  { id: 7, name: 'Débito Pré-pago' },
  { id: 8, name: 'Crédito Pré-pago' },
];

export const tipoTransacao = [
  { id: 1, name: 'Desconhecido' },
  { id: 3, name: 'Pagamento' },
  { id: 4, name: 'Antecipação' },
  { id: 5, name: 'Cancelamento' },
  { id: 6, name: 'Ajuste à débito' },
  { id: 7, name: 'Ajuste à crédito' },
  { id: 8, name: 'Operação ajuste à débito' },
  { id: 9, name: 'Operação ajuste à crédito' },
];

export const tipoTransacaoNomes: any = {
  1: 'Desconhecido',
  3: 'Pagamento',
  4: 'Antecipação',
  5: 'Cancelamento',
  6: 'Ajuste à débito',
  7: 'Ajuste à crédito',
  8: 'Operação ajuste à débito',
  9: 'Operação ajuste à crédito',
};

export const statusConciliacaoNomes: any = {
  0: 'NÃO CONCILIADO',
  1: 'CONCILIADO',
};

export const statusConciliacao = [
  { id: 0, name: 'NÃO CONCILIADO' },
  { id: 1, name: 'CONCILIADO' },
  { id: 2, name: 'ATRASADO' },
];

export const statusTaxaAnalytics = [
  { id: 'orange', name: 'Cadastrar Taxa' },
  { id: 'green', name: 'Taxa Válida' },
  { id: 'red', name: 'Divergência de Taxa' },
];

export const statusConciliacaoColors = {
  CONCILIADO: adjustBrightness('#8691ce', 0.9),
  'NÃO CONCILIADO': adjustBrightness('#4cadda', 0.9),
};

const colorPalette = ['#75e260', '#6d77a8', '#a6bfd4', '#38669b', '#a5e778', '#66d3cd', '#4da072', '#4e6da5'];
export const empresaColors = (id: number) => {
  return colorPalette[id % colorPalette.length];
};

export const bancoColors = (id: number) => {
  return colorPalette[id % colorPalette.length];
};

export const estabelecimentoColors = (id: number) => {
  return colorPalette[id % colorPalette.length];
};
