import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, message } from 'antd';
import logoCard from '../../assets/login/logo-card.svg';
import { Link } from 'react-router-dom';
import { recoverPassword } from '../../store/states/usuario/actions';
import { FormSide, ImageSide, LoginWrapper, StyledForm } from './styled';
import { RootState } from '../../store/types';

const RecoverPassword: React.FC = () => {
  const dispatch = useDispatch();
  const resetLoading = useSelector((state: RootState) => state.usuario.loading);
  const [form] = Form.useForm<unknown>();

  const onFinish = (value: any) => {
    try {
      dispatch(recoverPassword(value.email));
      form.resetFields();
    } catch (error: any) {
      message.error(error);
    }
  };

  return (
    <LoginWrapper>
      <FormSide>
        <StyledForm
          style={{ background: '#fff', padding: 35, borderRadius: 12 }}
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <div style={{ textAlign: 'left', marginBottom: 100 }}>
            <img src={logoCard} alt="Logo" style={{ width: 250 }} />
          </div>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Por favor, insira seu e-mail para recuperação da senha!',
              },
            ]}
          >
            <Input placeholder="Digite seu e-mail" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={resetLoading}
              style={{ width: '100%', backgroundColor: '#252f64' }}
            >
              {!resetLoading ? 'Enviar Recuperação' : 'Enviando, aguarde...'}
            </Button>
          </Form.Item>
          <Link to="/login">
            <Button type="primary" htmlType="submit" style={{ marginTop: 35, backgroundColor: '#252f64' }} loading={resetLoading}>Realizar Login</Button>
          </Link>
        </StyledForm>
      </FormSide>
      <ImageSide />
    </LoginWrapper>
  );
};

export default RecoverPassword;
