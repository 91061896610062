import api from '../../../services/axios';

export async function processamentoApi(client_id: number, fila: string): Promise<any> {
    const params = {
        client_id: client_id,
        fila: fila,
    };
    const response = await api.post('/processamento', params);    
    return response.data;
}
