import {
  CREATE_USUARIO,
  CREATE_USUARIO_FAILURE,
  CREATE_USUARIO_SUCCESS,
  FETCH_USUARIO,
  FETCH_USUARIO_EVENTO,
  FETCH_USUARIO_EVENTO_FAILURE,
  FETCH_USUARIO_EVENTO_SUCCESS,
  FETCH_USUARIO_FAILURE,
  FETCH_USUARIO_PROFILE,
  FETCH_USUARIO_PROFILE_FAILURE,
  FETCH_USUARIO_PROFILE_SUCCESS,
  FETCH_USUARIO_SUCCESS,
  PROPOSTA,
  PROPOSTA_SUCCESS,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  USUARIO_SYNC_EMPRESA,
  USUARIO_SYNC_EMPRESA_FAILURE,
  USUARIO_SYNC_EMPRESA_SUCCESS,
  UPDATE_USUARIO_STATUS,
  UPDATE_USUARIO_STATUS_FAILURE,
  UPDATE_USUARIO_STATUS_SUCCESS,
  UPLOAD_USER_PHOTO,
  FETCH_PERMISSIONS,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_FAILURE,
  UPDATE_USER_PERMISSIONS,
  UPDATE_USER_PERMISSIONS_SUCCESS,
  UPDATE_USER_PERMISSIONS_FAILURE
} from './types';

export const fetchUsuario = () => ({
  type: FETCH_USUARIO,
});

export const fetchUsuarioSuccess = (data: any) => ({
  type: FETCH_USUARIO_SUCCESS,
  payload: data,
});

export const fetchUsuarioFailure = (error: any) => ({
  type: FETCH_USUARIO_FAILURE,
  payload: error,
});

export const fetchUserProfile = () => ({
  type: FETCH_USUARIO_PROFILE,
});

export const fetchUserProfileSuccess = (profile: any) => ({
  type: FETCH_USUARIO_PROFILE_SUCCESS,
  payload: profile,
});

export const fetchUserProfileFailure = (error: any) => ({
  type: FETCH_USUARIO_PROFILE_FAILURE,
  payload: error,
});

export const recoverPassword = (email: string) => ({
  type: RECOVER_PASSWORD,
  payload: { email },
});

export const recoverPasswordSuccess = () => ({
  type: RECOVER_PASSWORD_SUCCESS,
});

export const resetPassword = (subdomain: string, token: string | null, password: string) => ({
  type: RESET_PASSWORD,
  payload: { subdomain, token, password },
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const proposal = (proposal: any) => ({
  type: PROPOSTA,
  payload: { proposal },
});

export const proposalSuccess = () => ({
  type: PROPOSTA_SUCCESS,
});

export const uploadUserPhoto = (file: any) => ({
  type: UPLOAD_USER_PHOTO,
  payload: { file },
});

export const fetchUsuarioEvento = () => ({
  type: FETCH_USUARIO_EVENTO,
});

export const fetchUsuarioEventoSuccess = (data: any) => ({
  type: FETCH_USUARIO_EVENTO_SUCCESS,
  payload: data,
});

export const fetchUsuarioEventoFailure = (error: any) => ({
  type: FETCH_USUARIO_EVENTO_FAILURE,
  payload: error,
});

export const updateUsuarioStatus = (usuarioId: string, status: boolean) => ({
  type: UPDATE_USUARIO_STATUS,
  payload: { usuarioId, status },
});

export const updateUsuarioStatusSuccess = () => ({
  type: UPDATE_USUARIO_STATUS_SUCCESS,
});

export const updateUsuarioStatusFailure = (error: any) => ({
  type: UPDATE_USUARIO_STATUS_FAILURE,
  payload: error,
});

export const createUsuario = (
  nome: string,
  usuario: string,
  email: string,
  senha: string,
  clienteId: string,
) => ({
  type: CREATE_USUARIO,
  payload: { nome, usuario, email, senha, clienteId },
});

export const createUsuarioSuccess = () => ({
  type: CREATE_USUARIO_SUCCESS,
});

export const createUsuarioFailure = (error: any) => ({
  type: CREATE_USUARIO_FAILURE,
  payload: error,
});

export const syncUsuarioEmpresa = (email: string) => ({
  type: USUARIO_SYNC_EMPRESA,
  payload: { email },
});

export const syncUsuarioEmpresaSuccess = () => ({
  type: USUARIO_SYNC_EMPRESA_SUCCESS,
});

export const syncUsuarioEmpresaFailure = (error: any) => ({
  type: USUARIO_SYNC_EMPRESA_FAILURE,
  payload: error,
});

export const fetchPermissions = () => ({
  type: FETCH_PERMISSIONS,
});

export const fetchPermissionsSuccess = (permissions: any) => ({
  type: FETCH_PERMISSIONS_SUCCESS,
  payload: permissions,
});

export const fetchPermissionsFailure = (error: any) => ({
  type: FETCH_PERMISSIONS_FAILURE,
  payload: error,
});

export const updateUserPermissions = (usuarioId: any, permissions: any) => ({
  type: UPDATE_USER_PERMISSIONS,
  payload: { usuarioId, permissions },
});

export const updateUserPermissionsSuccess = () => ({
  type: UPDATE_USER_PERMISSIONS_SUCCESS,
});

export const updateUserPermissionsFailure = (error: any) => ({
  type: UPDATE_USER_PERMISSIONS_FAILURE,
  payload: error,
});
