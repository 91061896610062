import {
  FETCH_CLIENTE,
  FETCH_CLIENTE_SUCCESS,
  FETCH_CLIENTE_FAILTURE,
  UPDATE_CLIENTE_STATUS,
  UPDATE_CLIENTE_STATUS_SUCCESS,
  UPDATE_CLIENTE_STATUS_FAILURE,
} from './types';

export const fetchCliente = () => ({
  type: FETCH_CLIENTE,
});

export const fetchClienteSuccess = (data: any) => ({
  type: FETCH_CLIENTE_SUCCESS,
  payload: data,
});

export const fetchClienteFailure = (error: any) => ({
  type: FETCH_CLIENTE_FAILTURE,
  payload: error,
});

export const updateClienteStatus = (clienteId: string, status: boolean) => ({
  type: UPDATE_CLIENTE_STATUS,
  payload: { clienteId, status }
});

export const updateClienteStatusSuccess = () => ({
  type: UPDATE_CLIENTE_STATUS_SUCCESS,
});

export const updateClienteStatusFailure = (error: any) => ({
  type: UPDATE_CLIENTE_STATUS_FAILURE,
  payload: error,
});