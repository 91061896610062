import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { ColumnsType } from 'antd/es/table';

export const columnsImportacaoArquivo = (): ColumnsType<any> => {
  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'importacaoarquivoid',
      key: 'importacaoarquivoid',
      render: text => text,
    },
    {
      title: 'Caminho Arquivo',
      dataIndex: 'importacaoarquivopath',
      key: 'importacaoarquivopath',
      sorter: (a, b) => a.importacaoarquivopath.localeCompare(b.importacaoarquivopath),
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return record.importacaoarquivopath.toLowerCase().includes(value.toLowerCase());
      },
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: 'Detalhes',
      dataIndex: 'importacaoarquivodetalhes',
      key: 'importacaoarquivodetalhes',
      sorter: (a, b) => a.importacaoarquivodetalhes.localeCompare(b.importacaoarquivodetalhes),
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return record.importacaoarquivodetalhes.toLowerCase().includes(value.toLowerCase());
      },
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: 'Tamanho Arquivo',
      dataIndex: 'importacaoarquivotamanhoarquivo',
      key: 'importacaoarquivotamanhoarquivo',
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return record.importacaoarquivotamanhoarquivo === value.toString();
      },
      render: (text, record) => <div>{text}</div>,
    }
  ];
  return columns;
};
