import api from '../../../services/axios';
import { message } from 'antd';

export async function proposalApi(proposal: any): Promise<any> {
    const response = await api.post('/proposta', proposal);
    message.success(response.data.message);
}

export async function adquirenteApi(): Promise<any> {
    const response = await api.get('/adquirente/all', {});
    return response.data;
}

export async function bancoApi(): Promise<any> {
    const response = await api.get('/banco/all', {});
    return response.data;
}

export async function bandeiraApi(): Promise<any> {
    const response = await api.get('/bandeira/all', {});
    return response.data;
}

export async function modalidadeApi(): Promise<any> {
    const response = await api.get('/modalidade/all', {});
    return response.data;
}
