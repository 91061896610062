export const CLEAR_EMPRESA_DATA = 'CLEAR_EMPRESA_DATA';
export const FETCH_EMPRESA = 'FETCH_EMPRESA';
export const FETCH_EMPRESA_SUCCESS = 'FETCH_EMPRESA_SUCCESS';
export const FETCH_EMPRESA_FAILURE = 'FETCH_EMPRESA_FAILURE';
export const UPDATE_EMPRESA_ID = 'UPDATE_EMPRESA_ID';
export const UPDATE_EMPRESA_STATUS = 'UPDATE_EMPRESA_STATUS';
export const UPDATE_EMPRESA_STATUS_SUCCESS = 'UPDATE_EMPRESA_STATUS_SUCCESS';
export const UPDATE_EMPRESA_STATUS_FAILURE = 'UPDATE_EMPRESA_STATUS_FAILURE';
export const UPDATE_EMPRESA_NOME = 'UPDATE_EMPRESA_NOME';
export const UPDATE_EMPRESA_NOME_SUCCESS = 'UPDATE_EMPRESA_NOME_SUCCESS';
export const UPDATE_EMPRESA_NOME_FAILURE = 'UPDATE_EMPRESA_NOME_FAILURE';
