import React, { useState, useEffect } from 'react';
import { Layout, Menu, Drawer } from 'antd';
import {
  BarChartOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  CheckSquareOutlined,
  ClusterOutlined,
  ConsoleSqlOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  FileDoneOutlined,
  FundOutlined,
  FundViewOutlined,
  MenuOutlined,
  PercentageOutlined,
  ReadOutlined,
  SecurityScanOutlined,
  SettingOutlined,
  ShopOutlined,
  TeamOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import card from '../../assets/login/logo-card-helper.svg';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import PortalAjuda from '../PortalAjuda';
import SubMenu from 'antd/es/menu/SubMenu';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/types';

const { Sider } = Layout;

const Sidebar: React.FC<any> = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');
  const location = useLocation();
  const user = useSelector((state: RootState) => state.usuario.profile);
  const userPermissions = user?.permissions || [];

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 1000);

      if (window.innerWidth > 1000 && visible) {
        setVisible(false);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [visible]);

  useEffect(() => {
    const pathname = location.pathname;
    setSelectedMenuItem(pathname);
  }, [location]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onClose = () => {
    setVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const shouldShowText = !collapsed || isMobile;

  const content = (
    <Menu
      mode="inline"
      selectedKeys={[selectedMenuItem]}
      style={{ height: '100%' }}
      onClick={isMobile ? () => setVisible(false) : undefined}>
      <img style={{ padding: 20 }} src={card} alt="Card Logo" />
      <Menu.Item title="Dashboard" key="/dashboard" icon={<BarChartOutlined style={{ backgroundColor: '#252f64', color: 'white', borderRadius: 5, fontSize: 20, padding: 5 }} />}>
        <NavLink to="/dashboard">{shouldShowText && 'Dashboard'}</NavLink>
      </Menu.Item>
      {userPermissions.includes('MANAGER_TEAM') && (
        <SubMenu key="/gestao" icon={<SettingOutlined style={{ backgroundColor: '#252f64', color: 'white', borderRadius: 5, fontSize: 20, padding: 5 }} />} title={shouldShowText && 'Administração'}>
          <Menu.Item key="/clientes" icon={<UserSwitchOutlined />}>
            <NavLink to="/clientes">Clientes</NavLink>
          </Menu.Item>
          <Menu.Item key="/empresas" icon={<ShopOutlined />}>
            <NavLink to="/empresas">Empresas</NavLink>
          </Menu.Item>
          <Menu.Item key="/estabelecimentos" icon={<ClusterOutlined />}>
            <NavLink to="/estabelecimentos">Estabelecimentos</NavLink>
          </Menu.Item>
          <Menu.Item key="/importacao-arquivo" icon={<FileDoneOutlined />}>
            <NavLink to="/importacao-arquivo">Importações</NavLink>
          </Menu.Item>
          <Menu.Item key="/usuarios" icon={<TeamOutlined />}>
            <NavLink to="/usuarios">Usuários</NavLink>
          </Menu.Item>
          <Menu.Item title="Gestão de Taxas" key="/gestao-taxas" icon={<PercentageOutlined />}>
            <NavLink to="/gestao-taxas">{shouldShowText && 'Taxas'}</NavLink>
          </Menu.Item>
          <Menu.Item key="/processamento" icon={<ConsoleSqlOutlined />}>
            <NavLink to="/processamento">Processamento</NavLink>
          </Menu.Item>
          <Menu.Item key="/eventos" icon={<ReadOutlined />}>
            <NavLink to="/eventos">Eventos</NavLink>
          </Menu.Item>
          <Menu.Item key="/logs" icon={<SecurityScanOutlined />}>
            <NavLink to="/logs">Logs</NavLink>
          </Menu.Item>
        </SubMenu>
      )}
      <Menu.Item title="Vendas" key="/venda" icon={<ReadOutlined style={{ backgroundColor: '#252f64', color: 'white', borderRadius: 5, fontSize: 20, padding: 5 }} />}>
        <NavLink to="/venda">{shouldShowText && 'Vendas'}</NavLink>
      </Menu.Item>
      <SubMenu key="gestao-pagamento" icon={<CarryOutOutlined style={{ backgroundColor: '#252f64', color: 'white', borderRadius: 5, fontSize: 20, padding: 5 }} />} title={shouldShowText && 'Pagamentos'}>
        <Menu.Item key="/pagamento" icon={<CheckSquareOutlined />}>
          <NavLink to="/pagamento">Pagamentos</NavLink>
        </Menu.Item>
        <Menu.Item key="/agenda-recebimentos" icon={<CalendarOutlined />}>
          <NavLink to="/agenda-recebimentos">Recebíveis</NavLink>
        </Menu.Item>
        <Menu.Item key="/pagamento-consolidado" icon={<CarryOutOutlined />}>
          <NavLink to="/pagamento-consolidado">Consolidados</NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="gestao-cliente" icon={<FundOutlined style={{ backgroundColor: '#252f64', color: 'white', borderRadius: 5, fontSize: 20, padding: 5 }} />} title={shouldShowText && 'Analytics'}>
        <Menu.Item key="/analise-vendas" icon={<FundViewOutlined />}>
          <NavLink to="/analise-vendas">Vendas</NavLink>
        </Menu.Item>
        <Menu.Item key="/analise-pagamentos" icon={<FundViewOutlined />}>
          <NavLink to="/analise-pagamentos">Pagamentos</NavLink>
        </Menu.Item>
      </SubMenu>
      {!collapsed &&
        <PortalAjuda />}
    </Menu>
  );
  return (
    <>
      {isMobile && (
        <>
          <Drawer placement="left" closable={false} onClose={onClose} visible={visible} bodyStyle={{ padding: 0 }} width={isMobile ? 250 : undefined}>
            {content}
          </Drawer>
          <MenuOutlined onClick={showDrawer} style={{ padding: '10px', fontSize: '15px', lineHeight: '32px', cursor: 'pointer' }} />
        </>
      )}
      {!isMobile && (
        <Sider className={collapsed ? 'sider-collapsed' : ''} style={{ backgroundColor: 'white' }} width={250} collapsed={collapsed} onCollapse={toggleCollapsed}>
          <div style={{ backgroundColor: 'white' }}>
            {collapsed ? (
              <DoubleRightOutlined onClick={toggleCollapsed} style={{ padding: '10px', fontSize: '20px', cursor: 'pointer', color: '#252f64' }} />
            ) : (
              <DoubleLeftOutlined onClick={toggleCollapsed} style={{ padding: '10px', fontSize: '20px', cursor: 'pointer', color: '#252f64' }} />
            )}
            {content}
          </div>
        </Sider>
      )}
    </>
  );
};

export default Sidebar;
