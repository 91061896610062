import { takeLatest, call, put } from 'redux-saga/effects';
import { importacaoArquivoApi } from '../../target-api/api';
import { FETCH_IMPORTACAO_ARQUIVO } from './types';
import { message } from 'antd';
import { fetchImportacaoArquivoFailure, fetchImportacaoArquivoSuccess } from './actions';

export function* fetchImportacaoArquivoSaga(action: any) {
  try {
    const { page, pageSize } = action.payload;
    const data: any[] = yield call(importacaoArquivoApi, page, pageSize);
    if (data !== undefined) {
      yield put(fetchImportacaoArquivoSuccess(data));
    } else {
      message.warning('Falha ao retornar lista de importações de arquivo!');
    }
  } catch (error: any) {
    message.error('Falha ao buscar importações de arquivo.');
    yield put(fetchImportacaoArquivoFailure(error.toString()));
  }
}

export function* watchImportacaoArquivoSaga() {
  yield takeLatest(FETCH_IMPORTACAO_ARQUIVO, fetchImportacaoArquivoSaga);
}
