import { Reducer } from 'redux';
import {
  FETCH_IMPORTACAO_ARQUIVO,
  FETCH_IMPORTACAO_ARQUIVO_SUCCESS,
  FETCH_IMPORTACAO_ARQUIVO_FAILURE
} from './types';

const initialState = {
  loading: false,
  data: {},
  error: null
};

export const reducer: Reducer<any, any> = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_IMPORTACAO_ARQUIVO:
      return { ...state, loading: true };
    case FETCH_IMPORTACAO_ARQUIVO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_IMPORTACAO_ARQUIVO_FAILURE:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
