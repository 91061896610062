import { Reducer } from 'redux';
import {
  CLEAR_GESTAO_TAXA,
  CREATE_GESTAO_TAXA,
  CREATE_GESTAO_TAXA_FAILURE,
  CREATE_GESTAO_TAXA_SUCCESS,
  FETCH_GESTAO_TAXA,
  FETCH_GESTAO_TAXA_FAILURE,
  FETCH_GESTAO_TAXA_SUCCESS,
  IMPORT_GESTAO_TAXA,
  IMPORT_GESTAO_TAXA_FAILURE,
  IMPORT_GESTAO_TAXA_SUCCESS,
  UPDATE_GESTAO_TAXA,
  UPDATE_GESTAO_TAXA_FAILURE,
  UPDATE_GESTAO_TAXA_STATUS,
  UPDATE_GESTAO_TAXA_STATUS_FAILURE,
  UPDATE_GESTAO_TAXA_STATUS_SUCCESS,
  UPDATE_GESTAO_TAXA_SUCCESS,
} from './types';

const initialState = {
  loading: false,
  data: {},
  error: null
};

export const reducer: Reducer<any, any> = (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_GESTAO_TAXA:
      return { ...initialState };
    case FETCH_GESTAO_TAXA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_GESTAO_TAXA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_GESTAO_TAXA_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_GESTAO_TAXA:
      return {
        ...state,
        data: state.data.map((item: any) => (item.rate_register_id === action.payload.rateId ? { ...item, ...action.payload.updatedData } : item)),
        loading: true,
      };
    case UPDATE_GESTAO_TAXA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_GESTAO_TAXA_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_GESTAO_TAXA:
      return {
        ...state,
        data: [...state.data, action.payload.newRate],
        loading: true,
      };
    case CREATE_GESTAO_TAXA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_GESTAO_TAXA_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case IMPORT_GESTAO_TAXA:
      return {
        ...state,
        loading: true,
      };
    case IMPORT_GESTAO_TAXA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case IMPORT_GESTAO_TAXA_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_GESTAO_TAXA_STATUS:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_GESTAO_TAXA_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_GESTAO_TAXA_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
