import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

export const columnsEmpresa = (clientes: any[]): ColumnsType<any> => {
  const clienteOptions = clientes
    .map(cliente => ({
      text: cliente.clientenome,
      value: cliente.clienteid,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));
  const columns: ColumnsType<any> = [
    {
      title: 'Data de Criação',
      dataIndex: 'empresacreatedat',
      key: 'empresacreatedat',
      sorter: (a, b) => moment(a.empresacreatedat).unix() - moment(b.empresacreatedat).unix(),
      render: text => <div>{moment(text).format('DD/MM/YYYY HH:mm:ss')}</div>,
    },
    {
      title: 'Nome Empresa',
      dataIndex: 'empresanome',
      key: 'empresanome',
      sorter: (a, b) => a.empresanome.localeCompare(b.empresanome),
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return record.empresanome.toLowerCase().includes(value.toLowerCase());
      },
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: 'Documento Empresa',
      dataIndex: 'empresadocumento',
      key: 'empresadocumento',
      sorter: (a, b) => a.empresadocumento.localeCompare(b.empresadocumento),
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return record.empresadocumento.toLowerCase().includes(value.toLowerCase());
      },
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: 'Cliente',
      dataIndex: 'empresaclienteid',
      key: 'empresaclienteid',
      filters: clienteOptions,
      onFilter: (value, record) => record.empresaclienteid === value,
      render: (text: string, record: any) => {
        const cliente = clientes.find(c => c.clienteid === text);
        return <div>{cliente ? cliente.clientenome : 'Cliente não encontrado'}</div>;
      },
    },
    {
      title: 'E-mail',
      dataIndex: 'empresaemail',
      key: 'empresaemail',
      sorter: (a, b) => a.empresaemail.localeCompare(b.empresaemail),
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return record.empresaemail.toLowerCase().includes(value.toLowerCase());
      },
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: 'Telefone',
      dataIndex: 'empresatelefone',
      key: 'empresatelefone',
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return record.empresatelefone.toLowerCase().includes(value.toLowerCase());
      },
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: 'Situação',
      dataIndex: 'empresastatus',
      key: 'empresastatus',
      sorter: (a, b) => (a.empresastatus === b.empresastatus ? 0 : a.empresastatus ? -1 : 1),
      render: status => <Tag color={status ? 'green' : 'red'}>{status ? 'Ativa' : 'Inativa'}</Tag>,
      filters: [
        { text: 'Ativa', value: true },
        { text: 'Inativa', value: false }
      ],
      onFilter: (value, record) => record.empresastatus === value,
    },
  ];
  return columns;
};
