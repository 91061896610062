import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

export const columnsEstabelecimento = (clientes: any[], empresas: any[], adquirentes: any[]): ColumnsType<any> => {
  const clienteOptions = clientes ? clientes.map(cliente => ({
    text: cliente.clientenome,
    value: cliente.clienteid,
  })).sort((a, b) => a.text.localeCompare(b.text)) : [];
  const empresaOptions = empresas ? empresas.map(empresa => ({
    text: empresa.empresanome,
    value: empresa.empresaid,
  })).sort((a, b) => a.text.localeCompare(b.text)) : [];
  const adquirenteOptions = adquirentes ? adquirentes.map(adquirente => ({
    text: adquirente.adquirentenome,
    value: adquirente.adquirenteid,
  })).sort((a, b) => a.text.localeCompare(b.text)) : [];
  const columns: ColumnsType<any> = [
    {
      title: 'Data de Criação',
      dataIndex: 'estabelecimentocreatedat',
      key: 'estabelecimentocreatedat',
      sorter: (a, b) => moment(a.estabelecimentocreatedat).unix() - moment(b.estabelecimentocreatedat).unix(),
      render: text => <div>{moment(text).format('DD/MM/YYYY HH:mm:ss')}</div>,
    },
    {
      title: 'N° Estabelecimento',
      dataIndex: 'estabelecimentonumero',
      key: 'estabelecimentonumero',
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return record.estabelecimentonumero ? record.estabelecimentonumero.includes(value) : false;
      },
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: 'Empresa',
      dataIndex: 'estabelecimentoempresaid',
      key: 'estabelecimentoempresaid',
      filters: empresaOptions,
      onFilter: (value, record) => record.estabelecimentoempresaid === value,
      render: (text: string, record: any) => {
        const empresa = empresas ? empresas.find(e => e.empresaid === text) : [];
        return <div>{empresa ? empresa.empresanome : 'Empresa não encontrada'}</div>;
      },
    },
    {
      title: 'Cliente',
      dataIndex: 'estabelecimentoclienteid',
      key: 'estabelecimentoclienteid',
      filters: clienteOptions,
      onFilter: (value, record) => record.estabelecimentoclienteid === value,
      render: (text: string, record: any) => {
        const cliente = clientes ? clientes.find(c => c.clienteid === text) : [];
        return <div>{cliente ? cliente.clientenome : 'Cliente não encontrado'}</div>;
      },
    },
    {
      title: 'Adquirente',
      dataIndex: 'estabelecimentoadquirenteid',
      key: 'estabelecimentoadquirenteid',
      filters: adquirenteOptions,
      onFilter: (value, record) => record.estabelecimentoadquirenteid === value,
      render: (text: string, record: any) => {
        const adquirente = adquirentes ? adquirentes.find(a => a.adquirenteid === text) : [];
        return <div>{adquirente ? adquirente.adquirentenome : 'Adquirente não encontrada'}</div>;
      },
    },
    {
      title: 'Situação',
      dataIndex: 'estabelecimentostatus',
      key: 'estabelecimentostatus',
      sorter: (a, b) => (a.estabelecimentostatus === b.estabelecimentostatus ? 0 : a.estabelecimentostatus ? -1 : 1),
      render: status => <Tag color={status ? 'green' : 'red'}>{status ? 'Ativo' : 'Inativo'}</Tag>,
      filters: [
        { text: 'Ativo', value: true },
        { text: 'Inativo', value: false }
      ],
      onFilter: (value, record) => record.estabelecimentostatus === value,
    }
  ];
  return columns;
};
