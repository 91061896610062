import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

export const columnsUsuarioEvento = (usuarios: any[], clientes: any[]): ColumnsType<any> => {
  const usuarioOptions = usuarios ? usuarios.map(usuario => ({
    text: usuario.usuarionome,
    value: usuario.usuarioid,
  })).sort((a, b) => a.text.localeCompare(b.text)) : [];
  const clienteOptions = clientes ? clientes.map(cliente => ({
    text: cliente.clientenome,
    value: cliente.clienteid,
  })).sort((a, b) => a.text.localeCompare(b.text)) : [];
  const columns: ColumnsType<any> = [
    {
      title: 'Data de Criação',
      dataIndex: 'usuarioeventocreatedat',
      key: 'usuarioeventocreatedat',
      sorter: (a, b) => moment(a.empresacreatedat).unix() - moment(b.empresacreatedat).unix(),
      render: text => <div>{moment(text).format('DD/MM/YYYY HH:mm:ss')}</div>,
    },
    {
      title: 'Usuário',
      dataIndex: 'usuarioeventousuarioid',
      key: 'usuarioeventousuarioid',
      filters: usuarioOptions,
      onFilter: (value, record) => record.usuarioeventousuarioid === value,
      render: (text: string, record: any) => {
        const usuario = usuarios ? usuarios.find(u => u.usuarioid === text) : [];
        return <Tag color="blue">{usuario ? usuario.usuarionome : 'N/A'}</Tag>;
      },
    },
    {
      title: 'Ação realizada',
      dataIndex: 'usuarioeventoacao',
      key: 'usuarioeventoacao',
      sorter: (a, b) => a.usuarioeventoacao.localeCompare(b.usuarioeventoacao),
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Digite aqui"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys([e.target.value])}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return record.usuarioeventoacao.toLowerCase().includes(value.toLowerCase());
      },
      render: (text, record) => <div>{text}</div>,
    },
    {
      title: 'Cliente',
      dataIndex: 'usuarioeventoclienteid',
      key: 'usuarioeventoclienteid',
      filters: clienteOptions,
      onFilter: (value, record) => record.usuarioeventoclienteid === value,
      render: (text: string, record: any) => {
        const cliente = clientes ? clientes.find(c => c.clienteid === text) : [];
        return <Tag color="blue">{cliente ? cliente.clientenome : 'N/A'}</Tag>;
      },
    },
    {
      title: 'IP utilizado',
      dataIndex: 'usuarioeventoip',
      key: 'usuarioeventoip',
    },
    {
      title: 'Plataforma',
      dataIndex: 'usuarioeventoplataforma',
      key: 'usuarioeventoplataforma',
    },
  ];
  return columns;
};
