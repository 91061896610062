import { Card, Col, Row } from 'antd';
import {
    BankOutlined,
    ClockCircleOutlined,
    FieldNumberOutlined,
    PaperClipOutlined,
    PercentageOutlined,
} from '@ant-design/icons';
import moment from 'moment';

export const RenderImportacaoArquivo = () => (record: any) => {
    return (
        <Card bordered={false} style={{ marginTop: 16, marginBottom: 16, cursor: 'pointer' }}>
            <Row gutter={16}>
                <Col span={6}>
                    <div>
                        <ClockCircleOutlined style={{ marginRight: 10 }} />
                        <span>Data de criação: </span>
                        <strong>{moment(record.importacaoarquivocreatedat).format('DD/MM/YYYY hh:mm:ss')}</strong>
                    </div>
                    <div>
                        <PaperClipOutlined style={{ marginRight: 10 }} />
                        <span>Data Referência: </span>
                        <strong>{moment(record.importacaoarquivodatareferencia).format('DD/MM/YYYY')}</strong>
                    </div>
                    <div>
                        <BankOutlined style={{ marginRight: 10 }} />
                        <span>Cliente ID: </span>
                        <strong>{record.importacaoarquivoclienteid}</strong>
                    </div>
                    <div>
                        <FieldNumberOutlined style={{ marginRight: 10 }} />
                        <span>Referência (Bucket S3): </span>
                        <strong>{record.importacaoarquivoreferencebucket}</strong>
                    </div>
                    <div>
                        <PercentageOutlined style={{ marginRight: 10 }} />
                        <span>Empresa ID: </span>
                        <strong>{record.importacaoarquivoempresaid}</strong>
                    </div>
                    <div>
                        <PercentageOutlined style={{ marginRight: 10 }} />
                        <span>Estabelecimento ID: </span>
                        <strong>{record.importacaoarquivoestabelecimentoid}</strong>
                    </div>
                    <div>
                        <PercentageOutlined style={{ marginRight: 10 }} />
                        <span>Adquirente ID: </span>
                        <strong>{record.importacaoarquivoadquirenteid}</strong>
                    </div>
                    <div>
                        <PercentageOutlined style={{ marginRight: 10 }} />
                        <span>Tipo Serviço Sub ID: </span>
                        <strong>{record.importacaoarquivotiposervicosubid}</strong>
                    </div>
                    <div>
                        <PercentageOutlined style={{ marginRight: 10 }} />
                        <span>Tipo Extrato ID: </span>
                        <strong>{record.importacaoarquivotipoextrato}</strong>
                    </div>
                </Col>
            </Row>
        </Card >
    );
};
