import api from '../../../services/axios';
import { message } from 'antd';

export async function usuarioApi(): Promise<any> {
    const response = await api.get('/usuarios');
    return response.data;
}

export async function getUserRuleApi(): Promise<any> {
    const response = await api.get('/usuario-regras', {});
    return response.data;
}

export async function usuarioSyncEmpresaApi(usuarioEmail: string): Promise<any> {
    const params = { email: usuarioEmail };
    const response = await api.post('/usuario-sync-empresa', params);
    message.success('Vínculo do usuário com as empresas atualizado com sucesso!');
    return response.data;
}

export async function userProfileApi(): Promise<any> {
    const response = await api.get('/profile', {});
    return response.data;
}

export async function updateUsuarioStatusApi(usuarioId: string, status: boolean): Promise<any> {
    const params = {
        usuario_id: usuarioId,
        status: status
    };
    const response = await api.put('/usuario/update-status', params);
    return response.data;
}

export async function createUsuarioApi(
    nome: string,
    usuario: string,
    email: string,
    senha: string,
    clienteId: string
): Promise<any> {
    const params = {
        nome,
        usuario,
        email,
        senha,
        cliente_id: clienteId
    };
    const response = await api.post('/usuario/create', params);
    message.success('Usuário criado com sucesso!');
    return response.data;
}

export async function usuarioEventoApi(): Promise<any> {
    const response = await api.get('/usuario-evento');
    return response.data;
}

export async function uploadPhotoApi(formData: FormData): Promise<any> {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const response = await api.post('/upload-photo', formData, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function recoverPasswordApi(email: string): Promise<any> {
    const response = await api.post('/recover_password', { email });
    message.info(response.data.message);
    return response.data;
}

export async function resetPasswordApi(subdomain: string, token: string, password: string): Promise<any> {
    const response = await api.post(`/reset_password/${token}`, {
        subdomain: subdomain,
        new_password: password,
    });
    message.success(response.data.message);
}

export async function fetchPermissionsApi(): Promise<any> {
    const response = await api.get('/usuario-regras');
    return response.data;
}

export async function updateUserPermissionsApi(userId: string, permissions: string[]): Promise<any> {
    try {
        const requestBody = {
            usuario_id: userId,
            permissoes: permissions
        };
        const response = await api.put('/usuario/update-regra', requestBody);
        return response.data;
    } catch (error) {
        console.error('Erro ao atualizar permissões do usuário:', error);
        throw error;
    }
}
