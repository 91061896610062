import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Container } from '../../styles/GlobalStyles';
import { useEffect, useState } from 'react';
import AnimatedBanner from '../../components/AnimatedBanner';
import TableComponent from '../../components/TableComponent';
import { columnsUsuario } from './interfaces/columnsUsuario';
import { Col, Row } from 'antd';
import { StatCard } from '../../components/Charts/StatCard';
import ButtonRefreshList from '../../components/Common/ButtonRefreshList';
import { ReloadOutlined, UserAddOutlined } from '@ant-design/icons';
import { fetchUsuario, updateUsuarioStatus, syncUsuarioEmpresa, fetchPermissions, updateUserPermissions, recoverPassword } from '../../store/states/usuario/actions';
import { RenderUsuario } from './interfaces/renderUsuario';
import ModalCreateUser from '../../components/Modals/ModalCreateUser';
import { ModalPermissoes } from '../../components/Modals/ModalPermissoes';

function UsuarioScreen() {
    const dispatch = useDispatch();
    const usuario = useSelector((state: RootState) => state.usuario.data);
    const cliente = useSelector((state: RootState) => state.cliente.data);
    const permissions = useSelector((state: RootState) => state.usuario.permissions);
    const [usuarios, setUsuarios] = useState([]);
    const [totalUsuarios, setTotalUsuarios] = useState(0);
    const [totalAtivos, setTotalAtivos] = useState(0);
    const [totalInativos, setTotalInativos] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPermissionsModalVisible, setIsPermissionsModalVisible] = useState(false);
    const [currentPermissions, setCurrentPermissions] = useState([]);
    const [availablePermissions, setAvailablePermissions] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);

    useEffect(() => {
        dispatch(fetchUsuario());
        dispatch(fetchPermissions());
    }, []);

    useEffect(() => {
        if (permissions && permissions.length > 0) {
            setAvailablePermissions(permissions);
        }
    }, [permissions]);

    useEffect(() => {
        if (usuario && usuario.usuarios) {
            setUsuarios(usuario.usuarios);
            setTotalUsuarios(usuario.total_usuarios);
            setTotalAtivos(usuario.total_ativos);
            setTotalInativos(usuario.total_inativos);
        }
    }, [dispatch, usuario]);

    const handleRefresh = () => {
        dispatch(fetchUsuario());
    };

    const toggleStatus = (usuarioId: string, status: boolean) => {
        dispatch(updateUsuarioStatus(usuarioId, !status));
    };

    const toggleSyncUsuarioEmpresa = (usuarioEmail: string) => {
        dispatch(syncUsuarioEmpresa(usuarioEmail));
    };

    const toggleRecuperarSenha = (usuarioemail: string) => {
        dispatch(recoverPassword(usuarioemail));
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    }

    const openPermissionsModal = (userId: any, permissions: any) => {
        setSelectedUserId(userId);
        setCurrentPermissions(permissions);
        setIsPermissionsModalVisible(true);
    };

    const closePermissionsModal = () => {
        setIsPermissionsModalVisible(false);
        setSelectedUserId(null);
    };

    const handlePermissionsChange = (newPermissions: any) => {
        setCurrentPermissions(newPermissions);
    };

    const updatePermissions = () => {
        setIsPermissionsModalVisible(false);
        dispatch(updateUserPermissions(selectedUserId, currentPermissions));
    };

    return (
        <AnimatedBanner>
            <Container>
                <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
                    <Col flex="5">
                        <StatCard loadingData={false} title="Total Usuários" value={totalUsuarios} />
                    </Col>
                    <Col flex="5">
                        <StatCard loadingData={false} title="Ativos" value={totalAtivos} />
                    </Col>
                    <Col flex="5">
                        <StatCard loadingData={false} title="Inativos" value={totalInativos} />
                    </Col>
                </Row>
                <ButtonRefreshList loading={false} icon={<ReloadOutlined />} onClick={handleRefresh} text='Recarregar' />
                <ButtonRefreshList loading={false} icon={<UserAddOutlined />} onClick={showModal} text='Novo Usuário' />
                <TableComponent
                    loadingData={false}
                    data={usuarios}
                    columns={columnsUsuario(cliente.clientes)}
                    rowKeyId="usuarioid"
                    rowRender={RenderUsuario(toggleStatus, toggleSyncUsuarioEmpresa, openPermissionsModal, toggleRecuperarSenha)}
                    perPage={10}
                    expand={true}
                />
                <ModalCreateUser
                    title="Novo Usuário"
                    isOpen={isModalVisible}
                    onClose={handleCloseModal}
                    onCancel={handleCloseModal} />
                <ModalPermissoes
                    isVisible={isPermissionsModalVisible}
                    onOk={updatePermissions}
                    onCancel={closePermissionsModal}
                    permissions={availablePermissions}
                    onChange={handlePermissionsChange}
                    userPermissions={currentPermissions}
                />
            </Container>
        </AnimatedBanner>
    );
}

export default UsuarioScreen;
