import { takeLatest, call, put } from 'redux-saga/effects';
import { empresaApi, updateNomeEmpresaApi, updateStatusEmpresaApi } from '../../target-api/api';
import { fetchEmpresaSuccess, fetchEmpresaFailure, fetchEmpresa } from './actions';
import { FETCH_EMPRESA, UPDATE_EMPRESA_NOME, UPDATE_EMPRESA_STATUS } from './types';
import { message } from 'antd';

export function* fetchEmpresaSaga() {
  try {
    const data: any[] = yield call(empresaApi);
    if (data !== undefined) {
      yield put(fetchEmpresaSuccess(data));
    } else {
      message.warning('Falha ao retornar lista de empresas!');
    }
  } catch (error: any) {
    message.error('Falha ao buscar empresas.');
    yield put(fetchEmpresaFailure(error.toString()));
  }
}

export function* updateEmpresaStatusSaga(action: any) {
  try {
    const { empresaId, status } = action.payload;
    yield call(updateStatusEmpresaApi, empresaId, status);
    message.success('Status atualizado com sucesso!');
    yield put(fetchEmpresa());
  } catch (error: any) {
    message.error('Falha ao atualizar status da empresa.');
  }
}

export function* updateEmpresaNomeSaga(action: any) {
  try {
    const { empresaId, nome } = action.payload;
    yield call(updateNomeEmpresaApi, empresaId, nome);
    message.success('Nome atualizado com sucesso!');
    yield put(fetchEmpresa());
  } catch (error: any) {
    message.error('Falha ao atualizar nome da empresa.');
  }
}

export function* watchEmpresaSaga() {
  yield takeLatest(FETCH_EMPRESA, fetchEmpresaSaga);
  yield takeLatest(UPDATE_EMPRESA_STATUS, updateEmpresaStatusSaga);
  yield takeLatest(UPDATE_EMPRESA_NOME, updateEmpresaNomeSaga);
}
