import { Reducer } from 'redux';
import {
  CLEAR_EMPRESA_DATA,
  FETCH_EMPRESA_FAILURE,
  FETCH_EMPRESA,
  FETCH_EMPRESA_SUCCESS,
  UPDATE_EMPRESA_ID,
  UPDATE_EMPRESA_STATUS,
  UPDATE_EMPRESA_STATUS_SUCCESS,
  UPDATE_EMPRESA_STATUS_FAILURE,
  UPDATE_EMPRESA_NOME,
  UPDATE_EMPRESA_NOME_SUCCESS,
  UPDATE_EMPRESA_NOME_FAILURE
} from './types';

const initialState = {
  loading: false,
  data: {},
  error: null
};

export const reducer: Reducer<any, any> = (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_EMPRESA_DATA:
      return { ...{} };
    case FETCH_EMPRESA:
      return { ...state, loading: true };
    case FETCH_EMPRESA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_EMPRESA_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case UPDATE_EMPRESA_ID:
      return {
        ...state,
        companyID: action.payload,
      };
    case UPDATE_EMPRESA_STATUS:
      return { ...state, loading: true };
    case UPDATE_EMPRESA_STATUS_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_EMPRESA_STATUS_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case UPDATE_EMPRESA_NOME:
      return { ...state, data: action.payload, loading: true };
    case UPDATE_EMPRESA_NOME_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case UPDATE_EMPRESA_NOME_FAILURE:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
